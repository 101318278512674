$product-offer-background: color(element-background) !default;
$product-offer-padding: rem-calc(20) !default;
$product-offer-margin: rem-calc(15 0) !default;
$product-offer-display: flex !default;
$product-offer-align-items: null !default;
// Icon
$product-offer-icon-padding: rem-calc(0 20 0 0) !default;
$product-offer-icon-width: rem-calc(80) !default;
$product-offer-icon-width--large: null !default;
// Description
$product-offer-description-p-margin: 0 0 rem-calc(5) !default;
$product-offer-description-a-color: color(text) !default;
$product-offer-description-font: null !default;
$product-offer-description-padding: null !default;
$product-offer-description-padding--large: null !default;
$product-offer-description-color: null !default;
$product-offer-description-text-transform: null !default;
// Title
$product-offer-title-font-weight: bold !default;
$product-offer-title-font: #{rem-calc(14)} / 1.6 $font-primary !default;
$product-offer-title-text-transform: none !default;
$product-offer-title-font--large: null !default;
$product-offer-title-color: null !default;
$product-offer-title-margin: null !default;
$product-offer-title-margin--large: null !default;

@mixin lora-components-product-offer {
    .c-product-offer {
        background: $product-offer-background;
        display: $product-offer-display;
        margin: $product-offer-margin;
        padding: $product-offer-padding;
        text-decoration: none;
        align-items: $product-offer-align-items;
    }

    .c-product-offer__icon {
        max-width: $product-offer-icon-width;
        min-width: $product-offer-icon-width;
        padding: $product-offer-icon-padding;

        @include breakpoint(large) {
            max-width: $product-offer-icon-width--large;
            min-width: $product-offer-icon-width--large;
        }
    }

    .c-product-offer__description {
        font: $product-offer-description-font;
        padding: $product-offer-description-padding;
        color: $product-offer-description-color;
        text-transform: $product-offer-description-text-transform;

        @include breakpoint(large) {
            padding: $product-offer-description-padding--large;
        }

        p {
            margin: $product-offer-description-p-margin;
        }

        a:not(:hover) {
            color: $product-offer-description-a-color;
        }
    }

    .c-product-offer__title {
        font: $product-offer-title-font;
        font-weight: $product-offer-title-font-weight;
        text-transform: $product-offer-title-text-transform;
        color: $product-offer-title-color;
        margin: $product-offer-title-margin;

        @include breakpoint(large) {
            font: $product-offer-title-font--large;
            margin: $product-offer-title-margin--large;
        }
    }
}
