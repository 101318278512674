// Deprecated components
@import "../../100-deprecated/product/product-how-to";
// Related components
@import "../scrollbar";

// How To Steps
$product-how-to-step-color: color(text-secondary) !default;
$product-how-to-step-font: #{rem-calc(14)} / #{rem-calc(22)} $font-primary !default;
$product-how-to-step-font--large: null !default;
$product-how-to-step-margin: rem-calc(10 0 0) !default;
$product-how-to-step-margin--large: rem-calc(30 0 0) !default;
$product-how-to-step-text-transform: null !default;
$product-how-to-step-letter-spacing: null !default;
// How To Content
$product-how-to-content-text-align: center !default;
$product-how-to-content-text-align--large: left !default;
$product-how-to-content-justify-content--large: center !default;
$product-how-to-content-padding--large: rem-calc(0 15 0 0) !default;
$product-how-to-content-after-main-margin: rem-calc(35 0 0) !default;
$product-how-to-content-after-main-margin--large: rem-calc(120 0 0) !default;
$product-how-to-content-color: null !default;
// How To Image
$product-how-to-image-margin: rem-calc(30 0 0) !default;
$product-how-to-image-margin--large: 0 !default;
$product-how-to-image-padding--large: rem-calc(0 0 0 15) !default;
$product-how-to-image-after-main-margin--large: rem-calc(120 0 0) !default;
// Custom Counter
$product-how-to-counter-name: lora-how-to-counter !default;
$product-how-to-steps-counter-step-display: flex !default;
$product-how-to-steps-counter-step-display--large: block !default;
$product-how-to-steps-counter-step-align-items: null !default;
$product-how-to-steps-counter-step-position: intial !default;
$product-how-to-steps-counter-step-padding: null !default;
$product-how-to-steps-counter-step-padding--large: null !default;
$product-how-to-steps-counter-step-count-content: counter($product-how-to-counter-name) ". " !default;
$product-how-to-steps-counter-step-count-color: color(text) !default;
$product-how-to-steps-counter-step-count-display: block !default;
$product-how-to-steps-counter-step-count-display--large: block !default;
$product-how-to-steps-counter-step-count-font: #{rem-calc(18)} / #{rem-calc(22)} $font-primary !default;
$product-how-to-steps-counter-step-count-margin: 0 !default;
$product-how-to-steps-counter-step-count-margin--large: rem-calc(0 0 5) !default;
$product-how-to-steps-counter-step-count-width: rem-calc(30) !default;
$product-how-to-steps-counter-step-count-width--large: 100% !default;
$product-how-to-steps-separator-display: none !default;
$product-how-to-steps-separator-border-top: $global-border !default;
$product-how-to-steps-separator-width: 100% !default;
// How To Steps when video or cover image is present
$product-how-to-media-steps-margin: $product-how-to-video-steps-margin !default;
$product-how-to-media-steps-margin--large: $product-how-to-video-steps-margin--large !default;
$product-how-to-media-steps-max-height--large: $product-how-to-video-steps-max-height--large !default;
$product-how-to-media-steps-width--large: $product-how-to-video-steps-width--large !default;

@mixin lora-components-product-how-to {
    .c-product-how-to {
        display: flex;
        flex-wrap: wrap;
    }

    .c-product-how-to__main {
        display: flex;
        flex-basis: 100%;
        flex-flow: column;

        @include breakpoint(large) {
            flex-flow: row;
        }
    }

    .c-product-how-to__media {
        flex-grow: 1;
    }

    .c-product-how-to__steps {
        flex-shrink: 0;
        list-style: none;
    }

    .c-product-how-to__step {
        color: $product-how-to-step-color;
        font: $product-how-to-step-font;
        margin: $product-how-to-step-margin;
        text-transform: $product-how-to-step-text-transform;
        letter-spacing: $product-how-to-step-letter-spacing;

        &:first-child {
            margin: 0;
        }

        @include breakpoint(large) {
            margin: $product-how-to-step-margin--large;
            font: $product-how-to-step-font--large;
        }
    }

    .c-product-how-to__content {
        text-align: $product-how-to-content-text-align;
        flex-basis: 100%;
        color: $product-how-to-content-color;

        @include breakpoint(large) {
            display: flex;
            flex-basis: 50%;
            flex-flow: column;
            justify-content: $product-how-to-content-justify-content--large;
            padding: $product-how-to-content-padding--large;
            text-align: $product-how-to-content-text-align--large;
        }
    }

    .c-product-how-to__image {
        flex-basis: 100%;
        margin: $product-how-to-image-margin;

        @include breakpoint(large) {
            flex-basis: 50%;
            margin: $product-how-to-image-margin--large;
            padding: $product-how-to-image-padding--large;
        }
    }

    .c-product-how-to__main ~ .c-product-how-to__content {
        margin: $product-how-to-content-after-main-margin;

        @include breakpoint(large) {
            margin: $product-how-to-content-after-main-margin--large;
        }
    }

    .c-product-how-to__main ~ .c-product-how-to__image {
        @include breakpoint(large) {
            margin: $product-how-to-image-after-main-margin--large;
        }
    }

    // Steps Counter
    .c-product-how-to__steps.m-counter {
        counter-reset: $product-how-to-counter-name;

        .c-product-how-to__step {
            counter-increment: $product-how-to-counter-name;
            display: $product-how-to-steps-counter-step-display;
            align-items: $product-how-to-steps-counter-step-align-items;
            position: $product-how-to-steps-counter-step-position;
            padding: $product-how-to-steps-counter-step-padding;

            @include breakpoint(large) {
                display: $product-how-to-steps-counter-step-display--large;
                padding: $product-how-to-steps-counter-step-padding--large;
            }

            &::before {
                content: $product-how-to-steps-counter-step-count-content;
                color: $product-how-to-steps-counter-step-count-color;
                display: $product-how-to-steps-counter-step-count-display;
                flex-shrink: 0;
                font: $product-how-to-steps-counter-step-count-font;
                margin: $product-how-to-steps-counter-step-count-margin;
                width: $product-how-to-steps-counter-step-count-width;

                @include breakpoint(large) {
                    display: $product-how-to-steps-counter-step-count-display--large;
                    margin: $product-how-to-steps-counter-step-count-margin--large;
                    width: $product-how-to-steps-counter-step-count-width--large;
                }
            }

            &::after {
                display: $product-how-to-steps-separator-display;
                content: '';
                border-top: $product-how-to-steps-separator-border-top;
                width: $product-how-to-steps-separator-width;
                position: absolute;
                bottom: 0;
                #{$global-left}: 0;
            }

            &:last-child {
                padding: 0;

                &::after {
                    display: none;
                }
            }
        }
    }

    // How To component with Video or Cover Image
    .c-product-how-to.m-media {
        .c-product-how-to__steps {
            margin: $product-how-to-media-steps-margin;

            @include breakpoint(large) {
                @include lora-helper-scrollbar-vertical;

                margin: $product-how-to-media-steps-margin--large;
                max-height: $product-how-to-media-steps-max-height--large;
                width: $product-how-to-media-steps-width--large;
            }
        }
    }
}
