$product-variations-carousel-link-min-width: rem-calc(100);
$product-variations-carousel-link-min-width--large: auto;
$product-variations-carousel-link-color: color(dark);
$product-variations-carousel-link-font: #{rem-calc(12)} / 1.3 $font-primary;
$product-variations-carousel-link-selected-color: color(dark);
$product-variations-carousel-link-background: color(light);
$product-variations-carousel-link-border: 1px solid color(disabled);
$product-variations-carousel-link-selected-border: 2px solid color(dark);
$product-variations-carousel-link-selected-background: color(light);

// Selected state
$product-variations-carousel-link-selected-font: bold #{rem-calc(12)} / 1.25 $font-primary !default;

@import "@lora/05-components/product/product-variations-carousel";
