@import "../icon";

$product-service-icon-list: (
    (
        default: heart
    ),
    (
        default: heart-outline,
        active: heart
    ),
    (
        default: makeup
    ),
    (
        default: questionmark
    ),
    (
        default: customize
    ),
    (
        default: mirror
    )
) !default;

$product-service-icon: "user" !default;
$product-service-icon-color: color(text) !default;
$product-service-icon-size: rem-calc(21) !default;
$product-service-icon-color-hover: $product-service-icon-color !default;
$product-service-icon-label-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$product-service-icon-label-text-transform: null !default;
$product-service-icon-svg-margin: 0 !default;
$product-service-icon-label-margin: rem-calc(10 0 0) !default;
$product-service-icon-svg-selector: '.c-product-service-icon__svg' !default;
$product-service-icon-label-selector: '.c-product-service-icon__label' !default;
$product-service-icon-is-mask: true !default;

@mixin lora-components-product-service-icon {
    .c-product-service-icon {
        @include lora-helper-icon-svg(
            $icon: $product-service-icon,
            $size: $product-service-icon-size,
            $svg-selector: $product-service-icon-svg-selector,
            $is-mask: $product-service-icon-is-mask
        );

        &:hover,
        &:focus {
            @include lora-helper-icon-hover(
                $icon: $product-service-icon,
                $color-hover: $product-service-icon-color-hover,
                $svg-selector: $product-service-icon-svg-selector,
                $label-selector: $product-service-icon-label-selector,
                $is-mask: $product-service-icon-is-mask
            );
        }

        @include lora-helper-icon-base(
            $color: $product-service-icon-color,
            $label-font: $product-service-icon-label-font,
            $svg-margin: $product-service-icon-svg-margin,
            $label-margin: $product-service-icon-label-margin,
            $svg-selector: $product-service-icon-svg-selector,
            $label-selector: $product-service-icon-label-selector,
            $label-text-transform: $product-service-icon-label-text-transform
        );
    }
    
    /* stylelint-disable max-nesting-depth */
    @each $items in $product-service-icon-list {
        @each $state, $icon in $items {
            @if ($state == default) {
                .c-product-service-icon.m-#{$icon} {
                    @include lora-helper-icon-svg(
                        $icon: "#{$icon}",
                        $size: $product-service-icon-size,
                        $svg-selector: $product-service-icon-svg-selector,
                        $is-mask: $product-service-icon-is-mask
                    );
        
                    &:hover,
                    &:focus {
                        @include lora-helper-icon-hover(
                            $icon: "#{$icon}",
                            $svg-selector: $product-service-icon-svg-selector,
                            $label-selector: $product-service-icon-label-selector,
                            $color-hover: $product-service-icon-color-hover,
                            $is-mask: $product-service-icon-is-mask
                        );
                    }
                }
            }

            @if ($state == active) {
                .c-product-service-icon.m-#{map-deep-get($items, default)}.m-active {
                    @include lora-helper-icon-svg(
                        $icon: "#{map-deep-get($items, active)}",
                        $size: $product-service-icon-size,
                        $svg-selector: $product-service-icon-svg-selector,
                        $is-mask: $product-service-icon-is-mask
                    );
    
                    &:hover,
                    &:focus {
                        @include lora-helper-icon-hover(
                            $icon: "#{map-deep-get($items, active)}",
                            $svg-selector: $product-service-icon-svg-selector,
                            $label-selector: $product-service-icon-label-selector,
                            $color-hover: $product-service-icon-color-hover,
                            $is-mask: $product-service-icon-is-mask
                        );
                    }
                }
            }
        }
    }
    /* stylelint-enable */
}