$highlight-background: color(global-background) !default;
$highlight-background--large: null !default;
$highlight-color: color(text) !default;
$highlight-color--large: null !default;
$highlight-padding: rem-calc(20) !default;
$highlight-padding--large: rem-calc(40) !default;
$highlight-margin: rem-calc(0 0 40) !default;
$highlight-margin--large: rem-calc(0 0 30) !default;
// Secondary
$highlight-secondary-border: 1px solid color(border) !default;
$highlight-secondary-background: transparent !default;

@mixin lora-components-highlight {
    .c-highlight {
        background: $highlight-background;
        color: $highlight-color;
        padding: $highlight-padding;
        margin: $highlight-margin;

        @include breakpoint(large) {
            background: $highlight-background--large;
            color: $highlight-color--large;
            padding: $highlight-padding--large;
            margin: $highlight-margin--large;
        }

        &.m-secondary {
            background: $highlight-secondary-background;
            border: $highlight-secondary-border;
        }
    }
}