// Header
$product-reviews-header-margin: rem-calc(0 0 30) !default;
$product-reviews-header-margin--large: null !default;
// Title
$product-reviews-title-font: #{rem-calc(30)} / 1 $font-primary !default;
$product-reviews-title-font--large: null !default;
$product-reviews-title-margin: rem-calc(0 0 10) !default;
$product-reviews-title-margin--large: null !default;
// Subtitle
$product-reviews-subtitle-margin: 0 !default;
$product-reviews-subtitle-margin--large: null !default;
// Rating
$product-reviews-rating-total-font: #{rem-calc(18)} / 1 $font-primary !default;
$product-reviews-rating-total-font--large: null !default;
$product-reviews-rating-total-margin: rem-calc(0 10) !default;
$product-reviews-rating-total-margin--large: null !default;
// Table
$product-reviews-table-margin: rem-calc(20 0) !default;
$product-reviews-table-row-padding: rem-calc(10 0) !default;
$product-reviews-table-label-font: #{rem-calc(14)} / 1 $font-primary !default;
$product-reviews-table-value-font: #{rem-calc(14)} / 1 $font-primary !default;
$product-reviews-table-value-text-align: $global-right !default;
$product-reviews-table-content-padding: rem-calc(0 10) !default;
// Content
$product-reviews-content-margin: rem-calc(0 0 30) !default;
$product-reviews-content-margin--large: rem-calc(0 0 40) !default;
// Sorting
$product-reviews-tools-margin: rem-calc(0 0 20) !default;
$product-reviews-tools-margin--large: null !default;
$product-reviews-tools-border--medium-down: 1px solid color(dark) !default;
$product-reviews-tools-background--medium-down: color(light) !default;
// Filter
$product-reviews-filter-background--medium-down: color(light) !default;

@mixin lora-components-product-reviews {
    .c-product-reviews__header {
        margin: $product-reviews-header-margin;

        @include breakpoint(large) {
            margin: $product-reviews-header-margin--large;
        }
    }

    .c-product-reviews__title {
        font: $product-reviews-title-font;
        margin: $product-reviews-title-margin;

        @include breakpoint(large) {
            font: $product-reviews-title-font--large;
            margin: $product-reviews-title-margin--large;
        }
    }

    .c-product-reviews__subtitle {
        margin: $product-reviews-subtitle-margin;

        @include breakpoint(large) {
            margin: $product-reviews-subtitle-margin--large;
        }
    }

    .c-product-reviews__table {
        width: 100%;
        display: table;
        border-collapse: collapse;
        margin: $product-reviews-table-margin;
    }

    .c-product-reviews__table-row {
        display: table-row;
        padding: $product-reviews-table-row-padding;
    }

    .c-product-reviews__table-col {
        display: table-cell;
        vertical-align: middle;
    }

    .c-product-reviews__table-label {
        white-space: nowrap;
        font: $product-reviews-table-label-font;
    }

    .c-product-reviews__table-value {
        font: $product-reviews-table-value-font;
        text-align: $product-reviews-table-value-text-align;
    }

    .c-product-reviews__table-content {
        width: 100%;
        padding: $product-reviews-table-content-padding;
    }

    .c-product-reviews__content {
        margin: $product-reviews-content-margin;

        @include breakpoint(large) {
            margin: $product-reviews-content-margin--large;
        }
    }

    .c-product-reviews__tools {
        margin: $product-reviews-tools-margin;

        @include breakpoint(medium down) {
            border: $product-reviews-tools-border--medium-down;
            background: $product-reviews-tools-background--medium-down;
        }
        @include breakpoint(large) {
            margin: $product-reviews-tools-margin--large;
        }

        .c-select__field {
            @include breakpoint(medium down) {
                border: none;
            }
        }
    }

    .c-product-reviews__tools-item:not(:first-child) {
        @include breakpoint(medium down) {
            border-#{$global-left}: $product-reviews-tools-border--medium-down;
        }
    }

    .c-product-reviews__filter {
        @include breakpoint(medium down) {
            display: block;
            background: $product-reviews-filter-background--medium-down;
            height: 100%;
            #{$global-left}: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: z(plpSidebar);
        }
    }

    .c-product-reviews:not(.m-refinements-panel-active) .c-product-reviews__filter {
        @include breakpoint(medium down) {
            display: none;
        }
    }
}
