// Delivery date
$product-delivery-date-margin: rem-calc(15 0) !default;
$product-delivery-date-form-field-margin: 0 !default;
// Delivery date edit
$product-delivery-date-edit-margin: null !default;
$product-delivery-date-edit-margin--large: null !default;
// Delivery date message
$product-delivery-date-message-flex-direction: column !default;
$product-delivery-date-message-flex-direction--large: row !default;
$product-delivery-date-message-justify-content: center !default;
$product-delivery-date-message-justify-content--large: flex-end !default;
$product-delivery-date-message-align-items: center !default;
$product-delivery-date-message-align-items--large: null !default;
$product-delivery-date-message-margin: rem-calc(0 0 15) !default;
$product-delivery-date-message-margin--large: null !default;
// Delivery date form
$product-delivery-date-form-max-width--large: 80% !default;
// Delivery date title
$product-delivery-date-title-margin: 0 !default;
$product-delivery-date-title-margin--large: rem-calc(0 0 0 10) !default;
$product-delivery-date-title-text-decoration: underline !default;
$product-delivery-date-title-text-decoration--large: null !default;
$product-delivery-date-title-font: null !default;
$product-delivery-date-title-font--large: null !default;
$product-delivery-date-title-text-align: center !default;
$product-delivery-date-title-text-align--large: null !default;
$product-delivery-date-title-text-transform: null !default;
$product-delivery-date-title-text-transform--large: null !default;
$product-delivery-date-title-letter-spacing: null !default;
$product-delivery-date-title-hover-color: null !default;
$product-delivery-date-title-line-height: 1.5 !default;
$product-delivery-date-title-line-height--large: null !default;
$product-delivery-date-title-color: color(text) !default;
// Delivery date response
$product-delivery-date-response-margin: 0 !default;
$product-delivery-date-response-margin--large: null !default;
$product-delivery-date-response-text-decoration: none !default;
$product-delivery-date-response-text-decoration--large: null !default;
$product-delivery-date-response-font: null !default;
$product-delivery-date-response-font--large: null !default;
$product-delivery-date-response-text-align: center !default;
$product-delivery-date-response-text-align--large: null !default;
$product-delivery-date-response-text-transform: null !default;
$product-delivery-date-response-text-transform--large: null !default;
$product-delivery-date-response-color: null !default;
$product-delivery-date-response-color--large: null !default;
$product-delivery-date-response-letter-spacing: null !default;
$product-delivery-date-response-letter-spacing--large: null !default;

@mixin lora-components-product-delivery-date {
    .c-product-delivery-date {
        margin: $product-delivery-date-margin;

        &.m-submit-success {
            .c-product-delivery-date__edit {
                display: block;
                margin: $product-delivery-date-edit-margin;

                @include breakpoint(large) {
                    margin: $product-delivery-date-edit-margin--large;
                }
            }

            .c-product-delivery-date__calculate {
                display: none;
            }
        }
        
        // stylelint-disable max-nesting-depth
        &.m-opened {
            .c-product-delivery-date__form-row {
                display: block;
                text-align: right;

                @include breakpoint(medium down) {
                    text-align: center;
                }

                .c-product-delivery-date__form {
                    @include breakpoint(large) {
                        max-width: $product-delivery-date-form-max-width--large;
                        margin: 0 0 0 auto;
                    }
                }
                
                .c-product-delivery-date__field {
                    flex-basis: auto;

                    @include breakpoint(medium down) {
                        flex-grow: 2;
                    }
                }
            }
        }
        // stylelint-enable max-nesting-depth
    }

    .c-product-delivery-date__edit,
    .c-product-delivery-date__form-row {
        display: none;
    }

    .c-product-delivery-date__message {
        display: flex;
        flex-flow: $product-delivery-date-message-flex-direction wrap;
        justify-content: $product-delivery-date-message-justify-content;
        align-items: $product-delivery-date-message-align-items;
        margin: $product-delivery-date-message-margin;

        @include breakpoint(large) {
            flex-direction: $product-delivery-date-message-flex-direction--large;
            justify-content: $product-delivery-date-message-justify-content--large;
            align-items: $product-delivery-date-message-align-items--large;
            margin: $product-delivery-date-message-margin--large;
        }
    }

    .c-product-delivery-date__title {
        cursor: pointer;
        line-height: $product-delivery-date-title-line-height;
        color: $product-delivery-date-title-color;
        letter-spacing: $product-delivery-date-title-letter-spacing;

        @include breakpoint(large) {
            line-height: $product-delivery-date-title-line-height--large;
        }

        @include text-style(
            $font: (
                small: $product-delivery-date-title-font,
                large: $product-delivery-date-title-font--large
            ),
            $margin: (
                small: $product-delivery-date-title-margin,
                large: $product-delivery-date-title-margin--large
            ),
            $text-align: (
                small: $product-delivery-date-title-text-align,
                large: $product-delivery-date-title-text-align--large
            ),
            $text-transform: (
                small: $product-delivery-date-title-text-transform,
                large: $product-delivery-date-title-text-transform--large
            ),
            $text-decoration: (
                small: $product-delivery-date-title-text-decoration,
                large: $product-delivery-date-title-text-decoration--large
            )
        );

        &:hover {
            color: $product-delivery-date-title-hover-color;
        }
    }

    .c-product-delivery-date__response {
        @include text-style(
            $font: (
                small: $product-delivery-date-response-font,
                large: $product-delivery-date-response-font--large
            ),
            $margin: (
                small: $product-delivery-date-response-margin,
                large: $product-delivery-date-response-margin--large
            ),
            $text-align: (
                small: $product-delivery-date-response-text-align,
                large: $product-delivery-date-response-text-align--large
            ),
            $text-transform: (
                small: $product-delivery-date-response-text-transform,
                large: $product-delivery-date-response-text-transform--large
            ),
            $text-decoration: (
                small: $product-delivery-date-response-text-decoration,
                large: $product-delivery-date-response-text-decoration--large
            ),
            $color: (
                small: $product-delivery-date-response-color,
                large: $product-delivery-date-response-color--large
            ),
            $letter-spacing: (
                small: $product-delivery-date-response-letter-spacing,
                large: $product-delivery-date-response-letter-spacing--large
            )
        );
    }
}