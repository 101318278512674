$product-mosaic-tile-margin: rem-calc(0 0 10) !default;
$product-mosaic-tile-margin--large: rem-calc(0 0 20) !default;

@mixin lora-components-product-mosaic {
    .c-mosaic__tile {
        margin: $product-mosaic-tile-margin;

        @include breakpoint(large) {
            margin: $product-mosaic-tile-margin--large;
        }

        img {
            width: 100%;
        }
    }
}