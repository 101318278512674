$service-items-margin: rem-calc(15) !default;
$service-margin: 0 -#{$service-items-margin} !default;
$service-item-padding: 0 $service-items-margin !default;
$service-stackable-item-padding: 0 $service-items-margin rem-calc(20) !default;

@mixin lora-components-service {
    .c-service {
        margin: $service-margin;
    }

    .c-service__inner {
        display: flex;
    }

    .c-service__item {
        padding: $service-item-padding;

        @include breakpoint(large) {
            min-width: 50%;
        }
    }

    .c-service.m-swipable {
        overflow-x: scroll;

        @include breakpoint(large) {
            overflow: auto;
        }

        .c-service__item {
            min-width: 93vw;

            @include breakpoint(large) {
                min-width: 50%;
            }
        }
    }

    .c-service.m-stackable {
        .c-service__inner {
            display: block;
        }

        .c-service__item {
            min-width: 100%;
            padding: $service-stackable-item-padding;
        }
    }
}
