@import "../../05-components/accordion";

$product-review-background-color: color(light) !default;
$product-review-border-bottom: 1px solid color(border) !default;
$product-review-padding: rem-calc(20) !default;
$product-review-padding--large: rem-calc(20) !default;
$product-review-margin: rem-calc(0 0 20) !default;
$product-review-margin--large: rem-calc(0 0 30) !default;
// Author
$product-review-author-margin: 0 !default;
$product-review-author-margin--large: null !default;
// Userstats
$product-review-userstats-padding: 0 !default;
$product-review-userstats-margin: null !default;
$product-review-userstats-margin--large: 0 !default;
$product-review-userstats-padding--large: rem-calc(15 0) !default;
$product-review-userstats-border-bottom: null !default;
$product-review-userstats-border-bottom--large: rem-calc(1) solid color(border) !default;
// Userstats item
$product-review-userstats-item-padding: null !default;
$product-review-userstats-item-padding--large: null !default;
$product-review-userstats-value-margin: rem-calc(0 0 0 10) !default;
$product-review-userstats-value-text-align: $global-right !default;
// Title
$product-review-title-margin: rem-calc(5 0 0) !default;
$product-review-title-margin--large: null !default;
// Content
$product-review-content-padding: rem-calc(10 0) !default;
$product-review-content-padding--large: 0 !default;
$product-review-content-header-margin: rem-calc(0 0 10) !default;
$product-review-content-header-margin--large: null !default;
$product-review-product-info-margin: rem-calc(0 0 25 0) !default;
// Message
$product-review-message-margin: rem-calc(0 0 20) !default;
$product-review-message-margin--large: null !default;
// Message text
$product-review-message-text-margin: rem-calc(0 0 10) !default;
$product-review-message-text-margin--large: null !default;
// Message text short
$product-review-message-text-short-lines: 3 !default;
$product-review-message-text-short-lines--large: null !default;
// Action
$product-review-action-title-margin: rem-calc(0 0 5) !default;
$product-review-action-title-margin--large: null !default;
// Toggler
$product-review-toggler-padding: rem-calc(0 15 0 0) !default;
$product-review-toggler-border-bottom: 1px solid color(dark) !default;
$product-review-toggler-font: 500 #{rem-calc(12)} / 1.25 $font-primary !default;
$product-review-toggler-text-transform: uppercase !default;
$product-review-toggler-text-align: $global-left !default;
$product-review-toggler-text-decoration: none !default;
$product-review-toggler-color: color(text) !default;
$product-review-toggler-active-color: color(primary-active) !default;
$product-review-toggler-letter-spacing: null !default;
// Toggler icon
$product-review-toggler-icon-width: rem-calc(8) !default;
$product-review-toggler-icon-font-size: rem-calc(8) !default;
$product-review-toggler-icon-margin: rem-calc(0 2 0 5) !default;
$product-review-toggler-icon-transition: transform 0.3s !default;
$product-review-toggler-icon-color: color(primary) !default;
$product-review-toggler-icon-margin-top: -(rem-calc(7)) !default;
// Reply
$product-review-reply-padding: rem-calc(0 0 0 10) !default;
$product-review-reply-padding--large: null !default;
$product-review-reply-border: 3px solid color(border) !default;

$product-review-image-width: rem-calc(90) !default;
$product-review-image-width--large: auto !default;
$product-review-image-margin: rem-calc(0 10 0 0) !default;

$product-review-actions-margin-top: rem-calc(10) !default;

$product-review-info-verifed-padding: rem-calc(10 0 0 0) !default;
$product-review-info-verifed-icon-size: rem-calc(10) !default;
$product-review-info-verifed-margin: rem-calc(0 0 10 0) !default;
$product-review-info-verifed-margin--large: null !default;

@mixin lora-components-product-review {
    .c-product-review {
        background: $product-review-background-color;
        border-bottom: $product-review-border-bottom;
        padding: $product-review-padding;
        margin: $product-review-margin;

        @include breakpoint(large) {
            padding: $product-review-padding--large;
            margin: $product-review-margin--large;
        }
    }

    .c-product-review__profile {
        @include breakpoint(medium down) {
            @include lora-helper-accordion-item-collapsed;
        }
    }

    .c-product-review__profile-header {
        display: flex;
    }

    .c-product-review__author {
        word-break: break-word;
        margin: $product-review-author-margin;

        @include breakpoint(large) {
            margin: $product-review-author-margin--large;
        }
    }

    .c-product-review-header {
        display: flex;
    }

    .c-product-review__image {
        width: $product-review-image-width;
        margin: $product-review-image-margin;

        @include breakpoint(large) {
            width: $product-review-image-width--large;
        }
    }

    .c-product-review__profile.m-expanded {
        > .c-product-review__author .c-product-review__author-button {
            @include breakpoint(medium down) {
                @include lora-helper-accordion-control-expanded;
            }
        }

        > .c-product-review__info {
            @include breakpoint(medium down) {
                @include lora-animation-expanded;
            }
        }
    }

    .c-product-review__author-button {
        @include breakpoint(medium down) {
            @include lora-helper-accordion-control-collapsed;

            cursor: pointer;

            & > .c-accordion__icon::after {
                margin-#{$global-right}: 0;
            }
        }
    }

    .c-product-review__info {
        @include breakpoint(medium down) {
            @include lora-animation-collapsed;
        }
    }

    .c-product-review__userstats {
        padding: $product-review-userstats-padding;
        margin: $product-review-userstats-margin;
        border-bottom: $product-review-userstats-border-bottom;

        @include breakpoint(large) {
            padding: $product-review-userstats-padding--large;
            margin: $product-review-userstats-margin--large;
            border-bottom: $product-review-userstats-border-bottom--large;
        }
    }

    .c-product-review__userstats-item {
        width: 100%;
        padding: $product-review-userstats-item-padding;

        @include breakpoint(large) {
            padding: $product-review-userstats-item-padding--large;
        }
    }

    .c-product-review__userstats-value {
        margin: $product-review-userstats-value-margin;
        text-align: $product-review-userstats-value-text-align;
    }

    .c-product-review__title {
        margin: $product-review-title-margin;

        @include breakpoint(large) {
            margin: $product-review-title-margin--large;
        }
    }

    .c-product-review__main-title {
        a {
            text-decoration: none;
        }
    }

    .c-product-review__content {
        padding: $product-review-content-padding;
        word-break: break-word;

        @include breakpoint(large) {
            padding: $product-review-content-padding--large;
        }
    }

    .c-product-review__product-info {
        margin: $product-review-product-info-margin;
    }

    .c-product-review__content-header {
        margin: $product-review-content-header-margin;

        @include breakpoint(large) {
            margin: $product-review-content-header-margin--large;
        }
    }

    .c-product-review__action-title {
        margin: $product-review-action-title-margin;

        @include breakpoint(large) {
            margin: $product-review-action-title-margin--large;
        }
    }

    .c-product-review__actions {
        margin-top: $product-review-actions-margin-top;

        .c-account-reviews__write-reviews {
            position: relative;
            width: auto;
        }
    }

    .c-product-review__message {
        margin: $product-review-message-margin;

        @include breakpoint(large) {
            margin: $product-review-message-margin--large;
        }
    }

    .c-product-review__message-text {
        margin: $product-review-message-text-margin;

        @include breakpoint(large) {
            margin: $product-review-message-text-margin--large;
        }
    }

    .c-product-review__message-text-short {
        @include text-line-clamp($product-review-message-text-short-lines);

        @include breakpoint(large) {
            @include text-line-clamp($product-review-message-text-short-lines--large);
        }
    }

    .c-product-review__message.m-expanded {
        .c-product-review__toggler {
            color: $product-review-toggler-active-color;

            &::after {
                content: '―';
                transform: rotateZ(0);
            }
        }
    }

    .c-product-review__info-verifed {
        padding: $product-review-info-verifed-padding;
        margin: $product-review-info-verifed-margin;

        .c-icon.m-tick .c-icon__svg::before {
            width: $product-review-info-verifed-icon-size;
            height: $product-review-info-verifed-icon-size;
        }

        @include breakpoint(large) {
            margin: $product-review-info-verifed-margin--large;
        }
    }

    .c-product-review__toggler {
        cursor: pointer;
        position: relative;
        padding: $product-review-toggler-padding;
        border-bottom: $product-review-toggler-border-bottom;
        font: $product-review-toggler-font;
        text-transform: $product-review-toggler-text-transform;
        text-align: $product-review-toggler-text-align;
        color: $product-review-toggler-color;
        letter-spacing: $product-review-toggler-letter-spacing;

        &::after {
            font-size: $product-review-toggler-icon-font-size;
            margin: $product-review-toggler-icon-margin;
            width: $product-review-toggler-icon-width;
            position: absolute;
            content: '✕';
            #{$global-right}: 0;
            top: 50%;
            margin-top: $product-review-toggler-icon-margin-top;
            text-align: center;
            transform: rotateZ(45deg);
            transition: $product-review-toggler-icon-transition;
            color: $product-review-toggler-icon-color;
        }
    }

    .c-product-review__reply {
        padding: $product-review-reply-padding;
        border-#{$global-left}: $product-review-reply-border;

        @include breakpoint(large) {
            padding: $product-review-reply-padding--large;
        }
    }
}
