$product-availability-display: flex !default;
$product-availability-float: none !default;
$product-availability-text-align: $global-right !default;
$product-availability-block-display--medium-down: none !default;
// Label
$product-availability-label-font: #{rem-calc(12)} / 1.25 $font-primary !default;
$product-availability-label-font--large: null !default;
$product-availability-label-text-transform: uppercase !default;
$product-availability-label-color: color(text) !default;
$product-availability-label-margin: rem-calc(0) !default;
$product-availability-label-letter-spacing: null !default;
// Message
$product-availability-message-width: 100% !default;
$product-availability-message-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-availability-message-color: color(text) !default;
$product-availability-message-margin: rem-calc(10 0 20) !default;
$product-availability-message-padding: rem-calc(20) !default;
$product-availability-message-text-align: $global-left !default;
$product-availability-message-background: color(element-background) !default;
// Estimated Delivery Date
$product-availability-estimated-delivery-date-color: $product-availability-label-color !default;
$product-availability-estimated-delivery-date-font: $product-availability-label-font !default;
$product-availability-estimated-delivery-date-font--large: $product-availability-label-font--large !default;
$product-availability-estimated-delivery-date-letter-spacing: null !default;
$product-availability-estimated-delivery-date-text-transform: $product-availability-label-text-transform !default;
$product-availability-estimated-delivery-date-before-content: '●' !default;
$product-availability-estimated-delivery-date-before-margin: rem-calc(0 4 0 6) !default;
$product-availability-estimated-delivery-date-cart-item-text-transform: none !default;
$product-availability-estimated-delivery-date-cart-item-color: color(text-secondary) !default;
$product-availability-estimated-delivery-date-cart-item-font: inherit !default;
$product-availability-estimated-delivery-date-cart-item-font--large: null !default;
$product-availability-estimated-delivery-date-cart-item-margin: 0 !default;
// Cart Item display
$product-availability-cart-item-display: block !default;
$product-availability-cart-item-label-font: bold #{rem-calc(12)} / 1 $font-primary !default;
$product-availability-cart-item-label-font--large: bold #{rem-calc(12)} / 1 $font-primary !default;
$product-availability-cart-item-label-text-transform: uppercase !default;
$product-availability-cart-item-label-color: color(text) !default;
$product-availability-cart-item-label-margin: 0 !default;
$product-availability-justify-content: flex-end !default;
$product-availability-justify-content--medium-down: null !default;
$product-availability-align-items: center !default;
// Has background
$product-availability-label-has-background-color: color(dark) !default;
$product-availability-label-has-background-font: bold #{rem-calc(12)} / 1.5 $font-primary !default;
$product-availability-label-has-background-padding: rem-calc(4 8) !default;
$product-availability-label-has-background-border: null !default;
$product-availability-label-has-background-border-radius: rem-calc(4) !default;
$product-availability-label-has-background-text-transform: none !default;
$product-availability-label-has-background-in-stock-background: color(success-secondary) !default;
$product-availability-label-has-background-out-of-stock-background: color(error-secondary) !default;
$product-availability-label-has-background-low-stock-background: color(warning-secondary) !default;
$product-availability-label-has-background-backorder-background: color(element-background) !default;
$product-availability-label-has-background-preorder-background: color(element-background) !default;
// Has icon
$product-availability-label-has-icon-before-width: rem-calc(10) !default;
$product-availability-label-has-icon-before-height: rem-calc(10) !default;
$product-availability-label-has-icon-before-border-radius: 50% !default;
$product-availability-label-has-icon-before-margin: rem-calc(0 5 0 0) !default;
$product-availability-label-has-icon-before-in-stock-background: color(success) !default;
$product-availability-label-has-icon-before-out-of-stock-background: color(alert) !default;
$product-availability-label-has-icon-before-low-stock-background: color(warning) !default;
$product-availability-label-has-icon-before-backorder-background: color(dark) !default;
$product-availability-label-has-icon-before-preorder-background: color(dark) !default;

@mixin lora-components-product-availability {
    .c-product-availability {
        display: $product-availability-display;
        float: $product-availability-float;
        flex-wrap: wrap;
        justify-content: $product-availability-justify-content;
        align-items: $product-availability-align-items;

        @include breakpoint(medium down) {
            display: $product-availability-block-display--medium-down;
            justify-content: $product-availability-justify-content--medium-down;
        }

        &.m-in-stock {
            .c-product-availability__label.m-has-background {
                background: $product-availability-label-has-background-in-stock-background;
            }

            .c-product-availability__label.m-has-icon::before {
                background: $product-availability-label-has-icon-before-in-stock-background;
            }
        }

        &.m-out-of-stock {
            .c-product-availability__label.m-has-background {
                background: $product-availability-label-has-background-out-of-stock-background;
            }

            .c-product-availability__label.m-has-icon::before {
                background: $product-availability-label-has-icon-before-out-of-stock-background;
            }
        }

        &.m-low-stock {
            .c-product-availability__label.m-has-background {
                background: $product-availability-label-has-background-low-stock-background;
            }

            .c-product-availability__label.m-has-icon::before {
                background: $product-availability-label-has-icon-before-low-stock-background;
            }
        }

        &.m-backorder {
            .c-product-availability__label.m-has-background {
                background: $product-availability-label-has-background-backorder-background;
            }

            .c-product-availability__label.m-has-icon::before {
                background: $product-availability-label-has-icon-before-backorder-background;
            }
        }

        &.m-preorder,
        &.m-low-preorder,
        &.m-preorder-ended {
            .c-product-availability__label.m-has-background {
                background: $product-availability-label-has-background-preorder-background;
            }

            .c-product-availability__label.m-has-icon::before {
                background: $product-availability-label-has-icon-before-preorder-background;
            }
        }
    }

    .c-product-availability__label {
        @include text-style(
            $font: (
                small: $product-availability-label-font,
                large: $product-availability-label-font--large
            ),
            $margin: (
                small: $product-availability-label-margin
            ),
            $text-transform: (
                small: $product-availability-label-text-transform
            ),
            $letter-spacing: (
                small: $product-availability-label-letter-spacing
            ),
            $color: (
                small: $product-availability-label-color
            )
        );

        position: relative;

        &.m-has-background {
            @include text-style(
                $font: (
                    small: $product-availability-label-has-background-font
                ),
                $padding: (
                    small: $product-availability-label-has-background-padding
                ),
                $text-transform: (
                    small: $product-availability-label-has-background-text-transform
                ),
                $color: (
                    small: $product-availability-label-has-background-color
                )
            );

            border: $product-availability-label-has-background-border;
            border-radius: $product-availability-label-has-background-border-radius;
        }

        &.m-has-icon {
            display: flex;
            align-items: center;

            &::before {
                content: '';
                display: block;
                width: $product-availability-label-has-icon-before-width;
                height: $product-availability-label-has-icon-before-height;
                border-radius: $product-availability-label-has-icon-before-border-radius;
                margin: $product-availability-label-has-icon-before-margin;
            }
        }
    }

    .c-product-availability__message {
        background: $product-availability-message-background;
        color: $product-availability-message-color;
        font: $product-availability-message-font;
        margin: $product-availability-message-margin;
        padding: $product-availability-message-padding;
        text-align: $product-availability-message-text-align;
        width: $product-availability-message-width;
    }

    .c-product__estimated-delivery-date {
        color: $product-availability-estimated-delivery-date-color;
        font: $product-availability-estimated-delivery-date-font;
        text-transform: $product-availability-estimated-delivery-date-text-transform;
        letter-spacing: $product-availability-estimated-delivery-date-letter-spacing;

        @include breakpoint(large) {
            font: $product-availability-estimated-delivery-date-font--large;
        }

        &::before {
            content: $product-availability-estimated-delivery-date-before-content;
            margin: $product-availability-estimated-delivery-date-before-margin;
        }

        &.m-cart-item {
            color: $product-availability-estimated-delivery-date-cart-item-color;
            font: $product-availability-estimated-delivery-date-cart-item-font;
            text-transform: $product-availability-estimated-delivery-date-cart-item-text-transform;
            margin: $product-availability-estimated-delivery-date-cart-item-margin;

            @include breakpoint(large) {
                font: $product-availability-estimated-delivery-date-cart-item-font--large;
            }

            &::before {
                display: none;
            }
        }
    }

    .c-product-availability.m-cart-item {
        display: $product-availability-cart-item-display;

        .c-product-availability__label {
            color: $product-availability-cart-item-label-color;
            font: $product-availability-cart-item-label-font;
            margin: $product-availability-cart-item-label-margin;
            text-transform: $product-availability-cart-item-label-text-transform;

            @include breakpoint(large) {
                font: $product-availability-cart-item-label-font--large;
            }
        }
    }
}
