// Info
$product-perfect-match-info-margin: rem-calc(0 0 30) !default;
$product-perfect-match-info-margin--large: rem-calc(0 0 40) !default;
/// Info variation
$product-perfect-match-info-variation-margin: rem-calc(10 0 0) !default;
$product-perfect-match-info-variation-margin--large: rem-calc(0 5) !default;
// Tile
$product-perfect-match-tile-margin: rem-calc(0 0 10) !default;
$product-perfect-match-tile-margin--large: rem-calc(0 0 20) !default;
/// Tile image
$product-perfect-match-tile-image-margin: rem-calc(0 0 20) !default;
$product-perfect-match-tile-image-margin--large: 0 !default;
/// Tile content
$product-perfect-match-tile-content-width: 100% !default;
$product-perfect-match-tile-content-width--large: null !default;
$product-perfect-match-tile-content-position: relative !default;
$product-perfect-match-tile-content-position--large: absolute !default;
$product-perfect-match-tile-content-left: 0 !default;
$product-perfect-match-tile-content-left--large: null !default;
$product-perfect-match-tile-content-bottom: 0 !default;
$product-perfect-match-tile-content-bottom--large: rem-calc(40) !default;
$product-perfect-match-tile-content-background: none !default;
$product-perfect-match-tile-content-background--large: null !default;
$product-perfect-match-tile-content-margin: 0 !default;
$product-perfect-match-tile-content-margin--large: null !default;
$product-perfect-match-tile-content-padding: 0 !default;
$product-perfect-match-tile-content-padding--large: null !default;
/// Tile name
$product-perfect-match-tile-name-font: 300 #{rem-calc(18)} / 1.4 $font-primary !default;
$product-perfect-match-tile-name-font--large: null !default;
$product-perfect-match-tile-name-color: color(text) !default;
$product-perfect-match-tile-name-color--large: null !default;
$product-perfect-match-tile-name-text-transform: uppercase !default;
$product-perfect-match-tile-name-text-transform--large: null !default;
$product-perfect-match-tile-name-margin: rem-calc(0 0 20) !default;
$product-perfect-match-tile-name-margin--large: null !default;

@mixin lora-components-product-perfect-match {
    .c-perfect-match__info {
        margin: $product-perfect-match-info-margin;

        @include breakpoint(large) {
            display: flex;
            flex-wrap: wrap;
            margin: $product-perfect-match-info-margin--large;
        }

        .c-product-variation {
            margin: $product-perfect-match-info-variation-margin;

            @include breakpoint(large) {
                margin: $product-perfect-match-info-variation-margin--large;
            }
        }
    }

    .c-perfect-match__tile {
        position: relative;
    }

    .c-perfect-match__tile-image {
        margin: $product-perfect-match-tile-image-margin;

        @include breakpoint(large) {
            margin: $product-perfect-match-tile-image-margin--large;
        }

        img {
            width: 100%;
        }
    }

    .c-perfect-match__tile-content {
        width: $product-perfect-match-tile-content-width;
        position: $product-perfect-match-tile-content-position;
        #{$global-left}: $product-perfect-match-tile-content-left;
        bottom: $product-perfect-match-tile-content-bottom;
        background: $product-perfect-match-tile-content-background;
        margin: $product-perfect-match-tile-content-margin;
        padding: $product-perfect-match-tile-content-padding;

        @include breakpoint(large) {
            width: $product-perfect-match-tile-content-width--large;
            position: $product-perfect-match-tile-content-position--large;
            #{$global-left}: $product-perfect-match-tile-content-left--large;
            bottom: $product-perfect-match-tile-content-bottom--large;
            background: $product-perfect-match-tile-content-background--large;
            margin: $product-perfect-match-tile-content-margin--large;
            padding: $product-perfect-match-tile-content-padding--large;
        }

        .c-product-tile__name {
            height: auto;
            
            @include text-style(
                $color: (
                    small: $product-perfect-match-tile-name-color,
                    large: $product-perfect-match-tile-name-color--large
                ),
                $font: (
                    small: $product-perfect-match-tile-name-font,
                    large: $product-perfect-match-tile-name-font--large
                ),
                $margin: (
                    small: $product-perfect-match-tile-name-margin,
                    large: $product-perfect-match-tile-name-margin--large
                ),
                $text-transform: (
                    small: $product-perfect-match-tile-name-text-transform,
                    large: $product-perfect-match-tile-name-text-transform--large
                )
            );
        }
    }
}