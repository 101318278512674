$button-border: 1px solid color(primary);
$button-secondary: (
    background: color(secondary),
    background-hover: color(element-background),
    color: color(primary)
);
$button-disabled-background: color(disabled);
$button-disabled-border: 1px solid color(disabled);
$button-disabled-color: color(dark);
$button-loading-icon-hover-color: color(dark);
$button-padding: rem-calc(11 10 10);
$button-alt-background: color(primary);
$button-alt-color: color(light);

@import "@lora/05-components/button";