$storelocator-geolocation-button-icon: map !default;
$storelocator-geolocation-button-icon-color: color(light) !default;
$storelocator-geolocation-button-icon-height: rem-calc(18) !default;
$storelocator-geolocation-button-icon-width: rem-calc(12) !default;
$storelocator-geolocation-button-icon-position--left: rem-calc(10) !default;
$storelocator-geolocation-button-icon-usesvg: true !default;
$storelocator-geolocation-button-hover-icon: $storelocator-geolocation-button-icon !default;
$storelocator-geolocation-button-hover-icon-color: $storelocator-geolocation-button-icon-color !default;
$storelocator-geolocation-button-hover-icon-usesvg: true !default;
$storelocator-geolocation-button-letter-spacing: null !default;
$storelocator-geolocation-button-text-align: null !default;
$storelocator-geolocation-button-padding: null !default;

@mixin lora-components-storelocator-geolocation-button {
    .c-storelocator-geolocation-button {
        position: relative;
        letter-spacing: $storelocator-geolocation-button-letter-spacing;
        text-align: $storelocator-geolocation-button-text-align;
        padding: $storelocator-geolocation-button-padding;

        &::before {
            @include svg-icon($storelocator-geolocation-button-icon, $storelocator-geolocation-button-icon-color, 100%, left, no-repeat, $usesvg: $storelocator-geolocation-button-icon-usesvg);

            content: '';
            @include vertical-center;

            height: $storelocator-geolocation-button-icon-height;
            width: $storelocator-geolocation-button-icon-width;
            #{$global-left}: $storelocator-geolocation-button-icon-position--left;
        }

        &:hover,
        &:active {
            &::before {
                @include svg-icon($storelocator-geolocation-button-hover-icon, $storelocator-geolocation-button-hover-icon-color, 100%, left, no-repeat, $usesvg: $storelocator-geolocation-button-hover-icon-usesvg);
            }
        }
    }
}
