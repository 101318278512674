// Layout
$product-main-v2-padding: rem-calc(0 0 15) !default;
$product-main-v2-padding--large: rem-calc(65 0 40) !default;
$product-main-v2-inner-display: flex !default;
$product-main-v2-inner-display--large: grid !default;
$product-main-v2-inner-flex-direction: column !default;
$product-main-v2-aside-display: none !default;
$product-main-v2-aside-width--large: percentage(1/3) !default;
$product-main-v2-aside-padding: 0 !default;
$product-main-v2-aside-padding--large: rem-calc(0 0 0 20) !default;
$product-main-v2-image-width--large: percentage(2/3) !default;
$product-main-v2-image-padding: rem-calc(0 0 20) !default;
$product-main-v2-image-padding--large: rem-calc(0 10 0 0) !default;
$product-main-v2-image-order: -1 !default;
$product-main-v2-image-order--large: null !default;
$product-main-v2-info-text-align: center !default;
$product-main-v2-info-text-align--large: null !default;
// Main Image
$product-main-v2-detail-image-padding: 0 !default;
$product-main-v2-detail-image-padding--large: null !default;
$product-main-v2-detail-image-mosaic-item-width: auto !default;
// Badge
$product-main-v2-detail-image-badge-margin: rem-calc(0 10) !default;
$product-main-v2-detail-image-badge-margin--large: 0 !default;
// Main Image view360
$product-main-v2-detail-image-view360-top--large: 0 !default;
$product-main-v2-detail-image-view360-right--large: calc(50% + #{rem-calc(10)}) !default;
// Product Name
$product-main-v2-name-font: #{rem-calc(24)} / 1.25 $font-primary !default;
$product-main-v2-name-font--large: #{rem-calc(30)} / 1 $font-primary !default;
$product-main-v2-name-margin: rem-calc(0 0 10) !default;
$product-main-v2-name-margin--large: rem-calc(0 0 20) !default;
// Product Subtitle
$product-main-v2-subtitle-color: color(text-secondary) !default;
$product-main-v2-subtitle-color--large: color(text-secondary) !default;
$product-main-v2-subtitle-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-main-v2-subtitle-font--large: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-main-v2-subtitle-margin: rem-calc(0 0 20) !default;
$product-main-v2-subtitle-margin--large: rem-calc(0 0 15) !default;
$product-main-v2-subtitle-text-transform: null !default;
$product-main-v2-subtitle-text-transform--large: null !default;
// Rating
$product-main-v2-rating-display: flex !default;
$product-main-v2-rating-display--large: flex !default;
$product-main-v2-rating-justify-content: center !default;
$product-main-v2-rating-justify-content--large: center !default;
// Product Price
$product-main-v2-price-border-bottom: 2px solid color(border) !default;
$product-main-v2-price-border-bottom--large: null !default;
$product-main-v2-price-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$product-main-v2-price-font--large: null !default;
$product-main-v2-price-margin: rem-calc(0 0 30) !default;
$product-main-v2-price-margin--large: rem-calc(0 0 25) !default;
$product-main-v2-price-padding: rem-calc(0 30 30) !default;
$product-main-v2-price-padding--large: rem-calc(0 30 15) !default;
$product-main-v2-price-color: null !default;
// Variation Section
$product-main-v2-variation-section-margin: rem-calc(0 0 30) !default;
$product-main-v2-variation-section-margin--large: rem-calc(0 0 40) !default;
// Availability
$product-main-v2-availability-margin: rem-calc(0 0 10) !default;
$product-main-v2-availability-margin--large: rem-calc(0 0 15) !default;
$product-main-v2-availability-justify-content: center !default;
$product-main-v2-availability-justify-content--large: null !default;
$product-main-v2-availability-label-color: color(text) !default;
$product-main-v2-availability-label-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-main-v2-availability-label-font--large: null !default;
$product-main-v2-availability-label-margin: rem-calc(0 5 0 0) !default;
$product-main-v2-availability-label-margin--large: null !default;
$product-main-v2-availability-label-text-transform: none !default;
$product-main-v2-estimated-delivery-date-color: color(text-secondary) !default;
$product-main-v2-estimated-delivery-date-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-main-v2-estimated-delivery-date-font--large: null !default;
$product-main-v2-estimated-delivery-date-text-transform: none !default;
// Delivery date message
$product-main-v2-delivery-date-message-flex-direction: column !default;
$product-main-v2-delivery-date-message-flex-direction--large: null !default;
$product-main-v2-delivery-date-message-justify-content: center !default;
$product-main-v2-delivery-date-message-justify-content--large: null !default;
$product-main-v2-delivery-date-message-align-items: center !default;
$product-main-v2-delivery-date-message-align-items--large: null !default;
$product-main-v2-delivery-date-message-margin: rem-calc(0 0 15) !default;
$product-main-v2-delivery-date-message-margin--large: null !default;
$product-main-v2-delivery-date-title-margin: 0 !default;
// Promotion
$product-main-v2-promotion-margin: rem-calc(0 0 10) !default;
$product-main-v2-promotion-margin--large: rem-calc(0 0 10) !default;
// Sticky
$product-main-v2-sticky-bar-item-actions-width--large: auto !default;

@mixin lora-components-product-main-v2 {
    .c-product-main.m-v2 {
        padding: $product-main-v2-padding;

        @include breakpoint(large) {
            padding: $product-main-v2-padding--large;

            &::before {
                display: $product-main-v2-aside-display;
            }
        }

        .c-product-main__inner {
            display: $product-main-v2-inner-display;
            flex-flow: $product-main-v2-inner-flex-direction;

            @include breakpoint(large) {
                display: $product-main-v2-inner-display--large;
                grid-template-columns: $product-main-v2-image-width--large $product-main-v2-aside-width--large;
            }
        }

        .c-product-main__info,
        .c-product-main__controls {
            padding: $product-main-v2-aside-padding;

            @include breakpoint(large) {
                padding: $product-main-v2-aside-padding--large;
                width: auto;
            }
        }

        .c-product-main__info {
            text-align: $product-main-v2-info-text-align;

            @include breakpoint(large) {
                text-align: $product-main-v2-info-text-align--large;
            }
        }

        .c-product-main__image {
            padding: $product-main-v2-image-padding;
            order: $product-main-v2-image-order;

            @include breakpoint(large) {
                order: $product-main-v2-image-order--large;
                padding: $product-main-v2-image-padding--large;
                width: auto;
            }
        }

        .c-product-detail-image__main {
            padding: $product-main-v2-detail-image-padding;

            @include breakpoint(large) {
                padding: $product-main-v2-detail-image-padding--large;
            }
        }

        .c-product-detail-image__main.m-single-column {
            .c-product-detail-image__mosaic-item {
                width: $product-main-v2-detail-image-mosaic-item-width;
            }
        }

        .c-product-detail-image__main:not(.m-single-column) {
            .c-product-detail-image__view360 {
                @include breakpoint(large) {
                    #{$global-right}: $product-main-v2-detail-image-view360-right--large;
                }
            }
        }

        .c-product-detail-image__badge {
            margin: $product-main-v2-detail-image-badge-margin;

            @include breakpoint(large) {
                margin: $product-main-v2-detail-image-badge-margin--large;
            }
        }

        .c-product-detail-image__view360 {
            @include breakpoint(large) {
                top: $product-main-v2-detail-image-view360-top--large;
            }
        }

        .c-product-main__name-wrapper {
            margin: $product-main-v2-name-margin;

            @include breakpoint(large) {
                margin: $product-main-v2-name-margin--large;
            }
        }

        .c-product-main__name {
            @include text-style(
                $font: (
                    small: $product-main-v2-name-font,
                    large: $product-main-v2-name-font--large
                )
            );
        }

        .c-product-main__subtitle {
            @include text-style(
                $font: (
                    small: $product-main-v2-subtitle-font,
                    large: $product-main-v2-subtitle-font--large
                ),
                $margin: (
                    small: $product-main-v2-subtitle-margin,
                    large: $product-main-v2-subtitle-margin--large
                ),
                $text-transform: (
                    small: $product-main-v2-subtitle-text-transform,
                    large: $product-main-v2-subtitle-text-transform--large
                ),
                $color: (
                    small: $product-main-v2-subtitle-color,
                    large: $product-main-v2-subtitle-color--large
                )
            );
        }

        .c-product-main__rating {
            display: $product-main-v2-rating-display;
            justify-content: $product-main-v2-rating-justify-content;

            @include breakpoint(large) {
                display: $product-main-v2-rating-display--large;
                justify-content: $product-main-v2-rating-justify-content--large;
            }

            &:empty {
                display: none;
            }
        }

        .c-product-main__price {
            @include text-style(
                $font: (
                    small: $product-main-v2-price-font,
                    large: $product-main-v2-price-font--large
                ),
                $margin: (
                    small: $product-main-v2-price-margin,
                    large: $product-main-v2-price-margin--large
                ),
                $padding: (
                    small: $product-main-v2-price-padding,
                    large: $product-main-v2-price-padding--large
                ),
                $color: (
                    small: $product-main-v2-price-color
                )
            );

            border-bottom: $product-main-v2-price-border-bottom;

            @include breakpoint(large) {
                border-bottom: $product-main-v2-price-border-bottom--large;
            }
        }

        .c-variation-section {
            margin: $product-main-v2-variation-section-margin;

            @include breakpoint(large) {
                margin: $product-main-v2-variation-section-margin--large;
            }
        }

        .c-product-main__availability {
            margin: $product-main-v2-availability-margin;

            @include breakpoint(large) {
                margin: $product-main-v2-availability-margin--large;
            }
        }

        .c-product-main__variations-dropdown.m-color {
            display: none;
        }

        .c-product-availability {
            display: flex;
            justify-content: $product-main-v2-availability-justify-content;

            @include breakpoint(large) {
                justify-content: $product-main-v2-availability-justify-content--large;
            }
        }

        .c-product-availability__label {
            @include text-style(
                $font: (
                    small: $product-main-v2-availability-label-font,
                    large: $product-main-v2-availability-label-font--large
                ),
                $margin: (
                    small: $product-main-v2-availability-label-margin,
                    large: $product-main-v2-availability-label-margin--large
                ),
                $text-transform: (
                    small: $product-main-v2-availability-label-text-transform
                ),
                $color: (
                    small: $product-main-v2-availability-label-color
                )
            );
        }

        .c-product__estimated-delivery-date {
            @include text-style(
                $font: (
                    small: $product-main-v2-estimated-delivery-date-font,
                    large: $product-main-v2-estimated-delivery-date-font--large
                ),
                $text-transform: (
                    small: $product-main-v2-estimated-delivery-date-text-transform
                ),
                $color: (
                    small: $product-main-v2-estimated-delivery-date-color
                )
            );

            &::before {
                display: none;
            }
        }

        .c-product-delivery-date__message {
            flex-flow: $product-main-v2-delivery-date-message-flex-direction;
            justify-content: $product-main-v2-delivery-date-message-justify-content;
            align-items: $product-main-v2-delivery-date-message-align-items;
            margin: $product-main-v2-delivery-date-message-margin;

            @include breakpoint(large) {
                flex-flow: $product-main-v2-delivery-date-message-flex-direction--large;
                justify-content: $product-main-v2-delivery-date-message-justify-content--large;
                align-items: $product-main-v2-delivery-date-message-align-items--large;
                margin: $product-main-v2-delivery-date-message-margin--large;
            }
        }

        // stylelint-disable max-nesting-depth
        .c-product-delivery-date {
            &.m-opened {
                .c-product-delivery-date__form-row {
                    text-align: center;
                }

                .c-product-delivery-date__form {
                    @include breakpoint(large) {
                        margin: 0 auto;
                    }
                }
            }
        }

        .c-product-delivery-date__field {
            flex-basis: auto;

            @include breakpoint(medium down) {
                flex-grow: 2;
            }
        }

        .c-product-delivery-date__title {
            margin: $product-main-v2-delivery-date-title-margin;
        }

        .c-product-promotion {
            margin: $product-main-v2-promotion-margin;

            @include breakpoint(large) {
                margin: $product-main-v2-promotion-margin--large;
            }
        }

        .c-product-sticky-bar.m-sticked {
            .c-product-main__actions {
                @include breakpoint(large) {
                    width: $product-main-v2-sticky-bar-item-actions-width--large;
                }
            }
        }
    }
}
