$product-service-tile-background: color(light) !default;
$product-service-tile-padding: rem-calc(8) !default;
$product-service-tile-border: null !default;
$product-service-tile-border-radius: rem-calc(4) !default;
$product-service-tile-box-shadow: rem-calc(0 0 2 0) rgba(color(dark), 0.2) !default;
$product-service-tile-img-border-radius: rem-calc(4) !default;
$product-service-tile-transition: box-shadow ease-out 0.3s !default;
$product-service-tile-hover-border-color: null !default;
$product-service-tile-hover-box-shadow: rem-calc(0 0 2 0) rgba(color(primary-active), 0.9) !default;
// Content
$product-service-tile-content-flex: 1 1 0 !default;
$product-service-tile-content-padding: rem-calc(8) !default;
// Button icon
$product-service-tile-button-padding: rem-calc(6 9) !default;
$product-service-tile-button-icon: chevron2-right !default;
$product-service-tile-button-icon-color: color(primary) !default;
$product-service-tile-button-icon-width: rem-calc(6) !default;
$product-service-tile-button-icon-height: rem-calc(12) !default;
$product-service-tile-button-icon-height--large: null !default;
$product-service-tile-button-icon-width--large: null !default;
$product-service-tile-button-icon-usesvg: true !default;

@mixin lora-components-product-service-tile {
    .c-product-service-tile {
        display: flex;
        width: 100%;
        cursor: pointer;
        background: $product-service-tile-background;
        padding: $product-service-tile-padding;
        border: $product-service-tile-border;
        border-radius: $product-service-tile-border-radius;
        box-shadow: $product-service-tile-box-shadow;
        text-decoration: none;
        transition: $product-service-tile-transition;

        img {
            border-radius: $product-service-tile-img-border-radius;
        }

        &:hover {
            border-color: $product-service-tile-hover-border-color;
            box-shadow: $product-service-tile-hover-box-shadow;
        }
    }

    .c-product-service-tile__content {
        display: block;
        flex: $product-service-tile-content-flex;
        padding: $product-service-tile-content-padding;
    }

    .c-product-service-tile__title {
        display: block;
    }

    .c-product-service-tile__subtitle {
        display: block;
    }

    .c-product-service-tile__button {
        padding: $product-service-tile-button-padding;
        line-height: 1;
        font-size: 0;

        &::after {
            @include svg-icon($product-service-tile-button-icon, $product-service-tile-button-icon-color, 100%, center, no-repeat, $usesvg: $product-service-tile-button-icon-usesvg);

            content: '';
            display: inline-block;
            height: $product-service-tile-button-icon-height;
            width: $product-service-tile-button-icon-width;

            @include breakpoint(large) {
                height: $product-service-tile-button-icon-height--large;
                width: $product-service-tile-button-icon-width--large;
            }
        }
    }
}