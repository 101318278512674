
                        $is-app_americas_ui-enabled: true;
                        $product-membershipproductlink-display: flex !default;
$product-membershipproductlink-text-decoration: none !default;
$product-membershipproductlink-icon: gift !default;
$product-membershipproductlink-icon-color: color(primary) !default;
$product-membershipproductlink-icon-size: rem-calc(13 13) !default;
$product-membershipproductlink-icon-position: left center !default;
$product-membershipproductlink-icon-repeat: no-repeat !default;

@mixin americas-components-product-membershipproductlink {
    .c-membershipproductlink {
        align-items: center;
        text-decoration: $product-membershipproductlink-text-decoration;
        display: $product-membershipproductlink-display;

        &::before {

            @include breakpoint(medium down) {
                @include svg-icon($product-membershipproductlink-icon, $product-membershipproductlink-icon-color, $product-membershipproductlink-icon-size, $product-membershipproductlink-icon-position, $product-membershipproductlink-icon-repeat);
            }
        }
    }
}
                    