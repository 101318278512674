$product-main-aside-background-color--large: color(global-background);
$product-main-border: none;
$product-main-image-position: relative;
$product-main-name-font: #{rem-calc(24)} / 1.25 $font-primary;
$product-main-name-font--large: #{rem-calc(24)} / 1.25 $font-primary;
$product-main-name-text-transform: uppercase;
$product-main-review-link-text-decoration: none;
$product-main-review-color: color(text-secondary);
$product-main-short-description-font: #{rem-calc(14)} / 1.25 $font-primary;
$product-main-short-description-readmore-float: none;
$product-main-subtitle-font: 700 #{rem-calc(14)} / 1.25 $font-secondary;
$product-main-subtitle-font--large: #{rem-calc(14)} / 1.25 $font-secondary;
$product-main-variations-dropdown-width--large: 60%;
$product-main-services-border-top: none;
$product-main-services-border-top--large: $product-main-services-border-top;
$product-main-services-border-bottom: none;
$product-main-services-border-bottom--large: $product-main-services-border-bottom;
$product-main-icons-top: 0;
$product-main-icons-right: rem-calc(11);
$product-main-price-font: #{rem-calc(12)} / 1.4 $font-secondary;
$product-main-price-font--large: #{rem-calc(14)} / 1.4 $font-secondary;
$product-main-recommendation-message-color: color(dark);
$product-main-recommendation-message-font: #{rem-calc(14)} / 1.25 $font-secondary;
$product-main-recommendation-title-font: #{rem-calc(18)} / 1.5 $font-primary;
$product-main-recommendation-title-font--large: #{rem-calc(24)} / 1.25 $font-primary;
$product-main-inner-grid-template-rows--large: auto;

@import "@lora/05-components/product/product-main";
