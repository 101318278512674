$image-zoom-main-margin: rem-calc(0 0 20) !default;
$image-zoom-main-padding: rem-calc(0 30) !default;
$image-zoom-main-padding--large: null !default;
$image-zoom-thumbnails-text-align: center !default;
$image-zoom-thumbnails-thumbs-padding: rem-calc(0) !default;
$image-zoom-thumbnails-thumbs-width: 100% !default;
$image-zoom-thumbnails-thumbs-height: rem-calc(70) !default;
$image-zoom-thumbnails-thumbs-width--large: rem-calc(340) !default;
$image-zoom-thumbnails-thumbs-height--large: null !default;
$image-zoom-image-cursor-icon: "zoom-in.cur" !default;
$image-zoom-image-cursor-icon--large: "zoom-in-large.cur" !default;
$image-zoom-image-zoomed-cursor-icon: "zoom-out.cur" !default;
$image-zoom-image-zoomed-cursor-icon--large: "zoom-out-large.cur" !default;
$image-zoom-image-cursor-position: 16 16 !default;
$image-zoom-image-cursor-position--large: 24 24 !default;

// Button
$image-zoom-button-background: color(light) !default;
$image-zoom-button-box-shadow: rem-calc(0 0 4 0) rgba(color(dark), 0.2) !default;
$image-zoom-button-border-radius: 50% !default;
$image-zoom-button-padding: rem-calc(8) !default;
$image-zoom-button-padding--large: null !default;
$image-zoom-button-icon: "search" !default;
$image-zoom-button-icon-size: rem-calc(24) !default;
$image-zoom-button-icon-size--large: rem-calc(24) !default;
$image-zoom-button-icon-color: color(primary) !default;
$image-zoom-button-icon-hover-color: color(primary-active) !default;
$image-zoom-button-icon-usesvg: true !default;

// Information
$image-zoom-information-background-color: color(element-background) !default;
$image-zoom-information-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$image-zoom-information-font--large: null !default;
$image-zoom-information-padding: rem-calc(20) !default;
$image-zoom-information-padding--large: rem-calc(10 20) !default;
$image-zoom-information-text-align: center !default;
$image-zoom-information-text-align--large: null !default;
$image-zoom-touched-information-position: fixed !default;
$image-zoom-touched-information-position--medium: absolute !default;

// Information icon
$image-zoom-information-icon: "handZoom" !default;
$image-zoom-information-icon-size: rem-calc(36) !default;
$image-zoom-information-icon-color: color(primary) !default;
$image-zoom-information-icon-margin: rem-calc(0 20 0 0) !default;
$image-zoom-information-icon-usesvg: true !default;

@mixin lora-components-image-zoom {
    .c-image-zoom__main {
        margin: $image-zoom-main-margin;
        padding: $image-zoom-main-padding;

        @include breakpoint(large) {
            padding: $image-zoom-main-padding--large;
        }

        .c-image-zoom__button {
            display: none;
        }
    }

    .c-image-zoom__image {
        display: none;
        width: 100%;
        height: 100%;
        cursor: zoom-in;
        cursor: url('#{$global-image-path}/#{$image-zoom-image-cursor-icon}') $image-zoom-image-cursor-position, auto;

        @include breakpoint(large) {
            cursor: url('#{$global-image-path}/#{$image-zoom-image-cursor-icon--large}') $image-zoom-image-cursor-position--large, auto;
        }

        &.m-zoomed {
            cursor: zoom-out;
            cursor: url('#{$global-image-path}/#{$image-zoom-image-zoomed-cursor-icon}') $image-zoom-image-cursor-position, auto;

            @include breakpoint(large) {
                cursor: url('#{$global-image-path}/#{$image-zoom-image-zoomed-cursor-icon--large}') $image-zoom-image-cursor-position--large, auto;
            }
        }
    }

    .c-image-zoom__thumbnails {
        text-align: $image-zoom-thumbnails-text-align;

        .c-product-thumbs {
            height: $image-zoom-thumbnails-thumbs-height;
            padding: $image-zoom-thumbnails-thumbs-padding;
            width: $image-zoom-thumbnails-thumbs-width;

            @include breakpoint(large) {
                height: $image-zoom-thumbnails-thumbs-height--large;
                width: $image-zoom-thumbnails-thumbs-width--large;
            }
        }

        .c-product-thumbs .c-carousel {
            display: inline-block;
        }
    }

    .c-image-zoom__button {
        cursor: pointer;
        display: block;
        background: $image-zoom-button-background;
        box-shadow: $image-zoom-button-box-shadow;
        border-radius: $image-zoom-button-border-radius;
        padding: $image-zoom-button-padding;

        @include breakpoint(large) {
            padding: $image-zoom-button-padding--large;
        }

        &::after {
            @include svg-icon($image-zoom-button-icon, $image-zoom-button-icon-color, 100%, center, no-repeat, $usesvg: $image-zoom-button-icon-usesvg);

            content: '';
            display: block;
            height: $image-zoom-button-icon-size;
            width: $image-zoom-button-icon-size;

            @include breakpoint(large) {
                height: $image-zoom-button-icon-size--large;
                width: $image-zoom-button-icon-size--large;
            }
        }

        &:hover,
        &:focus {
            &::after {
                @include svg-icon($image-zoom-button-icon, $image-zoom-button-icon-hover-color, 100%, center, no-repeat, $usesvg: $image-zoom-button-icon-usesvg);
            }
        }
    }

    .c-image-zoom__information {
        background: $image-zoom-information-background-color;
        display: none;
        font: $image-zoom-information-font;
        padding: $image-zoom-information-padding;
        text-align: $image-zoom-information-text-align;

        @include breakpoint(large) {
            font: $image-zoom-information-font--large;
            padding: $image-zoom-information-padding--large;
            text-align: $image-zoom-information-text-align--large;
        }

        &::before {
            @include svg-icon($image-zoom-information-icon, $image-zoom-information-icon-color, 100%, center, no-repeat, $usesvg: $image-zoom-information-icon-usesvg);

            content: '';
            display: inline-block;
            height: $image-zoom-information-icon-size;
            margin: $image-zoom-information-icon-margin;
            vertical-align: middle;
            width: $image-zoom-information-icon-size;
        }
    }

    .c-image-zoom.m-touched {
        .c-image-zoom__image {
            cursor: auto;
        }

        .c-image-zoom__information {
            bottom: 0;
            display: block;
            #{$global-left}: 0;
            position: $image-zoom-touched-information-position;
            width: 100%;
            z-index: z(stickyBar);

            @include breakpoint(medium) {
                position: $image-zoom-touched-information-position--medium;
            }
        }
    }

    .pinch-zoom-container {
        height: 0;
        padding-bottom: 100%;
        position: relative;
        width: 100%;

        .c-image-zoom__image {
            display: block;
        }
    }

    .c-image-zoom__imagelink {
        cursor: zoom-in;
    }
}
