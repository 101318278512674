// Swatches Grouped Container
$product-swatches-grouped-flex: auto !default;
// Swatches Grouped Toggle
$product-swatches-grouped-toggle-border-bottom: 1px solid color(dark) !default;
$product-swatches-grouped-toggle-font: 500 #{rem-calc(12)} / 1.25 $font-primary !default;
$product-swatches-grouped-toggle-font--large: #{rem-calc(14)} / 1.25 $font-primary !default;
$product-swatches-grouped-toggle-text-transform: uppercase !default;
$product-swatches-grouped-toggle-text-align: $global-left !default;
$product-swatches-grouped-toggle-padding: rem-calc(0 20 0 0) !default;
// Secondary toggle
$product-swatches-grouped-toggle-secondary-text-transform: none !default;
$product-swatches-grouped-toggle-secondary-padding: 0 !default;
$product-swatches-grouped-toggle-secondary-font: 500 #{rem-calc(12)} / 1.25 $font-primary !default;
$product-swatches-grouped-toggle-secondary-font--large: null !default;
// Swatches Grouped Toggle icon
$product-swatches-grouped-toggle-icon-width: rem-calc(8) !default;
$product-swatches-grouped-toggle-icon-font-size: rem-calc(8) !default;
$product-swatches-grouped-toggle-icon-margin: rem-calc(0 2 0 5) !default;
$product-swatches-grouped-toggle-icon-transition: transform 0.3s !default;
$product-swatches-grouped-toggle-icon-color: color(primary) !default;
$product-swatches-grouped-toggle-icon-margin-top: -(rem-calc(7)) !default;
$product-swatches-grouped-toggle-less-color: color(primary-active) !default;
// Swatches Group
$product-swatches-grouped-group-gap: 5 !default;
$product-swatches-grouped-group-gap--large: 6 !default;
$product-swatches-grouped-group-margin: rem-calc(0 -$product-swatches-grouped-group-gap) !default;
$product-swatches-grouped-group-margin--large: rem-calc(0 -$product-swatches-grouped-group-gap--large) !default;
$product-swatches-grouped-group-display--large: null !default;
$product-swatches-grouped-group-flex-wrap--large: null !default;
$product-swatches-grouped-group-flex-direction--large: null !default;
// Swatches Group Title
$product-swatches-grouped-title-color: color(text-secondary) !default;
$product-swatches-grouped-title-font: #{rem-calc(12)} / 1.25 $font-primary !default;
$product-swatches-grouped-title-margin: rem-calc(0 $product-swatches-grouped-group-gap 10) !default;
$product-swatches-grouped-title-margin--large: rem-calc(0 $product-swatches-grouped-group-gap--large 10) !default;
$product-swatches-grouped-title-text-transform: null !default;
// Tabs specific style
$product-swatches-grouped-tabs-margin: 0 !default;
$product-swatches-grouped-tabs-margin--large: null !default;
$product-swatches-grouped-tabs-controls-margin: 0 !default;
$product-swatches-grouped-tabs-controls-align-self: auto !default;
$product-swatches-grouped-tabs-controls-align-self--large: auto !default;
$product-swatches-grouped-tabs-control-items-margin: 0 !default;
$product-swatches-grouped-tabs-content-margin: rem-calc(20 0 5) !default;
$product-swatches-grouped-tab-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-swatches-grouped-tab-padding: rem-calc(5 15) !default;
$product-swatches-grouped-tab-padding--large: rem-calc(5 15) !default;
$product-swatches-grouped-tab-text-transform: none !default;
$product-swatches-grouped-tab-margin: rem-calc(0 0 0 -1) !default;
$product-swatches-grouped-tab-first-margin: 0 !default;
$product-swatches-grouped-tab-background: transparent !default;
$product-swatches-grouped-tab-color: color(text-secondary) !default;
$product-swatches-grouped-tab-border: 1px solid transparent !default;
$product-swatches-grouped-tab-border-radius: rem-calc(5) !default;
$product-swatches-grouped-tab-active-background: color(primary) !default;
$product-swatches-grouped-tab-active-color: color(light) !default;
$product-swatches-grouped-tab-active-border: 1px solid color(primary) !default;
// Swatch
$product-swatches-grouped-swatch-margin: rem-calc(0 $product-swatches-grouped-group-gap 15) !default;
$product-swatches-grouped-swatch-margin--large: rem-calc(0 $product-swatches-grouped-group-gap--large 15) !default;

@mixin lora-components-product-swatches-grouped {
    .c-swatches-grouped {
        flex: $product-swatches-grouped-flex;

        &.m-no-tabs {
            .c-tabs {
                margin-top: 0;
            }
        }
    }

    .c-swatches-grouped__toggle {
        position: relative;
        border-bottom: $product-swatches-grouped-toggle-border-bottom;
        font: $product-swatches-grouped-toggle-font;
        text-transform: $product-swatches-grouped-toggle-text-transform;
        text-align: $product-swatches-grouped-toggle-text-align;
        padding: $product-swatches-grouped-toggle-padding;

        @include breakpoint(large) {
            cursor: pointer;
            font: $product-swatches-grouped-toggle-font--large;
        }

        &::after {
            font-size: $product-swatches-grouped-toggle-icon-font-size;
            margin: $product-swatches-grouped-toggle-icon-margin;
            width: $product-swatches-grouped-toggle-icon-width;
            position: absolute;
            content: '✕';
            #{$global-right}: 0;
            top: 50%;
            margin-top: $product-swatches-grouped-toggle-icon-margin-top;
            text-align: center;
            transform: rotateZ(45deg);
            transition: $product-swatches-grouped-toggle-icon-transition;
            color: $product-swatches-grouped-toggle-icon-color;
        }

        &.m-active {
            &::after {
                content: '―';
                transform: rotateZ(0);
            }

            .c-swatches-grouped__toggle-more {
                display: none;
            }

            .c-swatches-grouped__toggle-less {
                display: inline-block;
            }
        }

        &.m-secondary {
            font: $product-swatches-grouped-toggle-secondary-font;
            text-transform: $product-swatches-grouped-toggle-secondary-text-transform;
            padding: $product-swatches-grouped-toggle-secondary-padding;

            @include breakpoint(large) {
                cursor: pointer;
                font: $product-swatches-grouped-toggle-secondary-font--large;
            }

            &::after {
                display: none;
            }
        }
    }

    .c-swatches-grouped__toggle-less {
        display: none;
        color: $product-swatches-grouped-toggle-less-color;
    }

    .c-swatches-grouped__toggle-content {
        display: none;

        &.m-active {
            display: block;

            @include breakpoint(large) {
                flex-basis: 100%;
            }
        }
    }

    .c-swatches-grouped__group {
        margin: $product-swatches-grouped-group-margin;

        @include breakpoint(large) {
            margin: $product-swatches-grouped-group-margin--large;
            display: $product-swatches-grouped-group-display--large;
            flex-flow: $product-swatches-grouped-group-flex-direction--large $product-swatches-grouped-group-flex-wrap--large;
        }

        .c-swatch {
            margin: $product-swatches-grouped-swatch-margin;
            transform: translate3d(0, 0, 0); // Fixes blinking items on iOS

            @include breakpoint(large) {
                margin: $product-swatches-grouped-swatch-margin--large;
            }
        }
    }

    .c-swatches-grouped__title {
        color: $product-swatches-grouped-title-color;
        font: $product-swatches-grouped-title-font;
        margin: $product-swatches-grouped-title-margin;
        text-transform: $product-swatches-grouped-title-text-transform;

        @include breakpoint(large) {
            margin: $product-swatches-grouped-title-margin--large;
        }
    }

    .c-tabs.m-swatches-grouped {
        margin: $product-swatches-grouped-tabs-margin;

        @include breakpoint(large) {
            margin: $product-swatches-grouped-tabs-margin--large;
        }

        .c-tabs__controls {
            margin: $product-swatches-grouped-tabs-controls-margin;
            align-self: $product-swatches-grouped-tabs-controls-align-self;

            @include breakpoint(large) {
                align-self: $product-swatches-grouped-tabs-controls-align-self--large;
            }
        }

        .c-tabs__control-items {
            margin: $product-swatches-grouped-tabs-control-items-margin;
        }

        .c-tabs__content {
            margin: $product-swatches-grouped-tabs-content-margin;
        }

        .c-tabs__tab {
            background: $product-swatches-grouped-tab-background;
            border: $product-swatches-grouped-tab-border;
            border-radius: $product-swatches-grouped-tab-border-radius;
            color: $product-swatches-grouped-tab-color;
            font: $product-swatches-grouped-tab-font;
            margin: $product-swatches-grouped-tab-margin;
            padding: $product-swatches-grouped-tab-padding;
            text-transform: $product-swatches-grouped-tab-text-transform;

            @include breakpoint(large) {
                padding: $product-swatches-grouped-tab-padding--large;
            }

            &:first-child {
                margin: $product-swatches-grouped-tab-first-margin;
            }

            &.m-active {
                background: $product-swatches-grouped-tab-active-background;
                border: $product-swatches-grouped-tab-active-border;
                color: $product-swatches-grouped-tab-active-color;

                &::after {
                    display: none;
                }
            }
        }
    }
}
