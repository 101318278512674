$pdp-margin--large: 0 !default;
$pdp-top-border: $global-border !default;
$pdp-top-inner-align-items: center !default;
$pdp-top-inner-display: flex !default;
$pdp-top-inner-justify-content: space-between !default;
$pdp-social-links-mobile-margin: rem-calc(20 $global-padding 0) !default;

// Bundle
$pdp-product-bundle-border-top: $global-border !default;
$pdp-product-bundle-margin: rem-calc(35 0 20) !default;
$pdp-product-bundle-title-name-flex: 1 0 0 !default;
$pdp-product-bundle-title-expanded-border-bottom: none !default;

@mixin lora-layout-pdp {
    .l-pdp {
        @include breakpoint(large) {
            margin: $pdp-margin--large;
        }
    }

    .l-pdp__top {
        border-bottom: $pdp-top-border;
    }

    .l-pdp__top-inner {
        @include layout;

        align-items: $pdp-top-inner-align-items;
        display: $pdp-top-inner-display;
        justify-content: $pdp-top-inner-justify-content;
    }

    .l-pdp__social-links {
        @include breakpoint(medium down) {
            display: none;
        }
    }

    .l-pdp__social-links-mobile {
        @include breakpoint(large) {
            display: none;
        }

        .c-social-share {
            margin: $pdp-social-links-mobile-margin;
        }
    }

    .l-pdp__back-to-parent {
        @include breakpoint(large) {
            display: none;
        }
    }

    // Bundle
    .l-pdp__main {
        .c-product-bundle {
            margin: $pdp-product-bundle-margin;
            border-top: $pdp-product-bundle-border-top;
        }

        .c-product-bundle__title-name {
            flex: $pdp-product-bundle-title-name-flex;
        }

        .c-product-bundle.m-expanded {
            .c-product-bundle__title {
                border-bottom: $pdp-product-bundle-title-expanded-border-bottom;
            }
        }

        @if $pdp-product-bundle-title-expanded-border-bottom == none or $pdp-product-bundle-title-expanded-border-bottom == 0 {
            .c-product-bundle__item {
                &:first-child {
                    padding-top: 0;
                }
            }
        }
    }
}
