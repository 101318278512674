// Deprecated components
@import "../../100-deprecated/product/product-variation-section";

$product-variation-section-margin: rem-calc(0 0 15) !default;
$product-variation-section-margin--large: null !default;

// Title
$product-variation-section-title-font: #{rem-calc(14)} / 1.25 $font-primary !default;
$product-variation-section-title-text-transform: uppercase !default;
$product-variation-section-title-width: 100% !default;
$product-variation-section-title-color: color(text) !default;
$product-variation-section-title-margin: rem-calc(0 0 15) !default;
$product-variation-section-title-margin--large: rem-calc(0 0 25) !default;
$product-variation-section-title-padding: rem-calc(0 190 0 0) !default;
// Content
$product-variation-section-content-margin: rem-calc(0 0 15) !default;
$product-variation-section-content-margin--large: rem-calc(0 0 25) !default;
$product-variation-section-content-justify-content: space-between !default;
// Swatch
$product-variation-section-swatch-selected-border-color: color(primary) !default;

@mixin lora-components-product-variation-section {
    .c-variation-section {
        margin: $product-variation-section-margin;
        position: relative;

        @include breakpoint(large) {
            margin: $product-variation-section-margin--large;
        }

        .c-swatch {
            @include lora-helper-swatch-selected($product-variation-section-swatch-selected-border-color);
        }

        & > :last-child {
            margin-bottom: 0;
        }
    }

    .c-variation-section__title {
        width: $product-variation-section-title-width;
        color: $product-variation-section-title-color;
        font: $product-variation-section-title-font;
        margin: $product-variation-section-title-margin;
        text-transform: $product-variation-section-title-text-transform;
        padding: $product-variation-section-title-padding;

        @include breakpoint(large) {
            margin: $product-variation-section-title-margin--large;
        }
    }

    .c-variation-section__content {
        position: relative;
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: $product-variation-section-content-justify-content;
        margin: $product-variation-section-content-margin;

        @include breakpoint(large) {
            margin: $product-variation-section-content-margin--large;
        }
    }
}
