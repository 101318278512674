$product-sticky-bar-box-shadow: rem-calc(0 16 20 0) rgba(color(dark), 0.1);
$product-sticky-bar-border-bottom: none;
$product-sticky-bar-item-image-border: none;
$product-sticky-bar-title-font-family: $font-secondary;
$product-sticky-bar-title-text-transform: none;
$product-sticky-bar-description-color: color(text-secondary);
$product-sticky-bar-title-font-size: rem-calc(14);
$product-sticky-bar-add-bag-width: 100%;
$product-sticky-bar-add-bag-flex-grow: 1;
$product-sticky-bar-quantity-margin: 0;
$product-sticky-bar-item-margin: 0;

@import "@lora/05-components/product/product-sticky-bar";