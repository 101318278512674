// Deprecated components
@import "../../100-deprecated/product/product-swatches";

$product-main-border: 1px solid color(border) !default;
$product-main-padding: rem-calc(25 0 15) !default;
$product-main-padding--large: rem-calc(65 0 40) !default;
$product-main-inner-grid-template-rows--large: 0fr 1fr !default; // stylelint-disable length-zero-no-unit
$product-main-aside-padding: rem-calc(0 35 0 0) !default;
$product-main-aside-padding--large: rem-calc(0 0 0 100) !default;
$product-main-aside-width--large: 50% !default;
$product-main-aside-is-have-background: true !default;
$product-main-aside-background-color--large: color(global-background) !default;
$product-main-image-padding: rem-calc(0 0 20) !default;
$product-main-image-padding--large: rem-calc(0 25 0 0) !default;
$product-main-image-position: null !default;
$product-main-image-position--large: relative !default;
$product-main-image-width--large: 50% !default;
$product-main-controls-width--large: $product-main-image-width--large !default;
$product-main-info-width--large: $product-main-image-width--large !default;
$product-main-info-text-align: $global-left !default;
$product-main-info-text-align--large: null !default;
$product-main-recommendation-width--large: $product-main-image-width--large !default;
$product-main-inner-columns: $product-main-image-width--large $product-main-aside-width--large !default;

// Recommendations Title
$product-main-recommendation-title-margin: rem-calc(0 0 20) !default;
$product-main-recommendation-title-margin--large: rem-calc(0 0 20) !default;
$product-main-recommendation-title-font: #{rem-calc(18)} / 1.5 $font-primary !default;
$product-main-recommendation-title-font--large: #{rem-calc(30)} / 1.25 $font-primary !default;
$product-main-recommendation-title-color: null !default;
$product-main-recommendation-title-text-transform: uppercase !default;
$product-main-recommendation-title-text-align: $global-left !default;
$product-main-recommendation-title-text-align--large: null !default;
// Recommendations Message
$product-main-recommendation-message-font: #{rem-calc(22)} / 1.25 $font-primary !default;
$product-main-recommendation-message-color: color(text-secondary) !default;
$product-main-recommendation-message-margin: rem-calc(0 0 25) !default;
$product-main-recommendation-message-margin--large: rem-calc(0 0 40) !default;
// Discontinued product
$product-main-discontinued-info--padding--large: rem-calc(25 25 0 0) !default;
$product-main-discontinued-info--width--large: 50% !default;

// Product variations dropdown
$product-main-variations-dropdown-width: 100% !default;
$product-main-variations-dropdown-width--large: rem-calc(270) !default;
$product-main-variations-dropdown-order: null !default;
$product-main-variations-dropdown-order--large: null !default;
$product-main-variations-dropdown-margin: rem-calc(20 0 0) !default;
$product-main-variations-dropdown-producttile-width: auto !default;
$product-main-variations-dropdown-top-margin: rem-calc(0 0 15) !default;

// Product Swatches Grouped
$product-main-swatches-grouped-margin: rem-calc(0 0 5) !default;
$product-main-swatches-grouped-margin--large: 0 !default;

// Product more shade toggle
$product-main__more-shade-width: 36% !default;
$product-main__more-shade-width--large: rem-calc(170) !default;
$product-main__more-shade-top: 0 !default;
$product-main__more-shade-right: 0 !default;
// Swatch Face Image
$product-main-preview-image-margin: $product-swatches-preview-image-margin !default;
$product-main-preview-image-margin--large: $product-swatches-preview-image-margin--large !default;
$product-main-preview-image-order: $product-swatches-preview-image-order !default;
$product-main-preview-image-order--large: $product-swatches-preview-image-order--large !default;
$product-main-preview-image-width: $product-swatches-preview-image-width !default;
$product-main-preview-image-width--large: $product-swatches-preview-image-width--large !default;
// Product icons
$product-main-icons-top: 0 !default;
$product-main-icons-top--large: rem-calc(20) !default;
$product-main-icons-right: rem-calc(15) !default;
$product-main-icons-right--large: rem-calc(45) !default;
// Product brand
$product-main-brand-font: #{rem-calc(14)} / 1.25 $font-primary !default;
// Product name
$product-main-name-font: #{rem-calc(24)} / 1.25 $font-primary !default;
$product-main-name-font--large: null !default;
$product-main-name-color: color(text) !default;
$product-main-name-margin: rem-calc(0 0 15) !default;
$product-main-name-margin--large: null !default;
$product-main-name-text-transform: none !default;
// Product Subtitle
$product-main-subtitle-display: block !default;
$product-main-subtitle-display--large: block !default;
$product-main-subtitle-font: #{rem-calc(14)} / 1.25 $font-primary !default;
$product-main-subtitle-font--large: #{rem-calc(18)} / 1.25 $font-primary !default;
$product-main-subtitle-color: color(text) !default;
$product-main-subtitle-color--large: null !default;
$product-main-subtitle-margin: rem-calc(0 0 7) !default;
$product-main-subtitle-margin--large: rem-calc(0 0 20) !default;
$product-main-subtitle-text-transform: none !default;
$product-main-subtitle-letter-spacing: null !default;
$product-main-subtitle-letter-spacing--large: null !default;
// Product headline
$product-main-headline-font: #{rem-calc(14)} / 1.25 $font-primary !default;
$product-main-headline-font--large: null !default;
$product-main-headline-color: color(text) !default;
$product-main-headline-margin: rem-calc(0 0 15) !default;
$product-main-headline-margin--large: null !default;
$product-main-headline-text-transform: uppercase !default;
// Recommendations Title
$product-main-recommendation-message-font: #{rem-calc(22)} / 1.25 $font-primary !default;
$product-main-recommendation-message-color: color(text-secondary) !default;
$product-main-recommendation-message-margin: rem-calc(0 0 25) !default;
$product-main-recommendation-message-margin--large: rem-calc(0 0 40) !default;
$product-main-recommendation-message-text-transform: null !default;
// Product Short Description
$product-main-short-description-font: #{rem-calc(14)} / 1.4 $font-primary !default;
$product-main-short-description-color: color(text-secondary) !default;
$product-main-short-description-margin: rem-calc(0 0 25) !default;
$product-main-short-description-display: none !default;
$product-main-short-description-text-transform: null !default;
$product-main-short-description-letter-spacing: null !default;
$product-main-short-description-readmore-color: color(text) !default;
$product-main-short-description-readmore-font: null !default;
$product-main-short-description-readmore-float: none !default;
$product-main-short-description-readmore-text-transform: none !default;
$product-main-short-description-readmore-letter-spacing: null !default;
// Product Rating
$product-main-review-font: #{rem-calc(16)} / 1.5 $font-primary !default;
$product-main-rating-margin: rem-calc(0 0 18) !default;
$product-main-rating-margin--large: rem-calc(0 0 18) !default;
$product-main-rating-rating-margin: rem-calc(0 4 2 0) !default;
$product-main-rating-rating-margin--large: null !default;
$product-main-rating-rating-font-size: rem-calc(14) !default;
$product-main-rating-rating-font-size--large: null !default;
$product-main-rating-rating-icon-margin: rem-calc(5) !default;
$product-main-review-color: color(text) !default;
$product-main-review-count-text-decoration: none !default;
$product-main-review-total-text-decoration: none !default;
$product-main-review-link-text-decoration: underline !default;
$product-main-review-link-color: color(text) !default;
$product-main-review-count-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$product-main-review-count-color: color(text-secondary) !default;
$product-main-review-total-color: $product-main-review-link-color !default;
$product-main-review-highlight-total-color: color(primary) !default;
$product-main-review-highlight-total-text-decoration: underline !default;
$product-main-review-highlight-total-font-weight: normal !default;
$product-main-review-highlight-total-font: $product-main-review-highlight-total-font-weight #{rem-calc(14)} / 1.5 $font-primary !default;
$product-main-review-highlight-icon-image-width: rem-calc(16) !default;
$product-main-review-highlight-icon-image-margin: rem-calc(0 0 0 4) !default;
$product-main-review-highlight-icon-margin: rem-calc(0 4) !default;
$product-main-review-highlight-icon-before-content: "-" !default;
$product-main-review-highlight-icon-before-font-size: rem-calc(14) !default;
$product-main-review-highlight-icon-before-display: inline-block !default;
$product-main-review-highlight-icon-before-margin: null !default;
$product-main-review-highlight-icon-before-color: color(dark) !default;
$product-main-review-info-margin: rem-calc(0 0 0 4) !default;
$product-main-review-info-icon: 'info' !default;
$product-main-review-info-icon-color: color(primary) !default;
$product-main-review-info-icon-size: rem-calc(16) !default;
$product-main-review-info-icon-svg-selector: '.c-product-main__review-info__svg' !default;
$product-main-review-info-icon-is-mask: true !default;
// Rating
$product-main-rating-display: flex !default;
$product-main-rating-display--large: null !default;
$product-main-rating-align-items: center !default;
$product-main-rating-align-items--large: null !default;
$product-main-rating-justify-content: null !default;
$product-main-rating-justify-content--large: null !default;
// Product Price
$product-main-price-font: #{rem-calc(18)} / 1.4 $font-primary !default;
$product-main-price-font--large: null !default;
$product-main-price-margin: rem-calc(0 0 15) !default;
$product-main-price-margin--large: null !default;
// Product Add to Bag
$product-main-actions-margin: rem-calc(0 0 10) !default;
$product-main-add-bag-flex-grow: 1 !default;
// Product Where to Buy
$product-main-where-to-buy-margin: 0 !default;
$product-main-where-to-buy-margin--large: rem-calc(5 0 0 0) !default;
$product-main-where-to-buy-flex: 1 1 100% !default;
$product-main-where-to-buy-select-min-width: 100% !default;
$product-main-where-to-buy-select-min-width--large: rem-calc(200) !default;
// Product Quantity Selector
$product-main-quantity-flex-basis: auto !default;
$product-main-quantity-margin--large: rem-calc(0 30 0 0) !default;
$product-main-quantity-margin: 0 !default;
// Error messages
$product-main-error-width: 100% !default;
$product-main-error-background--medium-down: color(light) !default;
$product-main-error-padding--medium-down: rem-calc(5 15) !default;
$product-main-error-margin--medium-down: 0 !default;
$product-main-error-bottom--medium-down: 100% !default;
// Shipping
$product-main-shipping-padding: rem-calc(5 0) !default;
$product-main-shipping-padding--large: null !default;
$product-main-shipping-margin: rem-calc(0 0 10) !default;
$product-main-shipping-margin--large: null !default;
$product-main-shipping-font: null !default;
$product-main-shipping-font--large: null !default;
$product-main-shipping-text-transform: null !default;
$product-main-shipping-letter-spacing: null !default;
// Services
$product-main-services-padding: rem-calc(10 0) !default;
$product-main-services-padding--large: rem-calc(15 0) !default;
$product-main-services-margin: rem-calc(0 0 10) !default;
$product-main-services-margin--large: null !default;
$product-main-services-border-top: 1px solid color(border) !default;
$product-main-services-border-top--large: null !default;
$product-main-services-border-bottom: 1px solid color(border) !default;
$product-main-services-border-bottom--large: null !default;
//Discount info
$product-main-discount-margin: rem-calc(0 0 5 100) !default; // left margin is related to a QTY size and margin
$product-main-discount-margin--large: rem-calc(0 0 5 130) !default;

@mixin lora-components-product-main {
    .c-product-main {
        padding: $product-main-padding;

        @include breakpoint(large) {
            border-bottom: $product-main-border;
            padding: $product-main-padding--large;
            position: relative;

            @if ($product-main-aside-is-have-background) {
                &::before {
                    background-color: $product-main-aside-background-color--large;
                    content: "";
                    height: 100%;
                    position: absolute;
                    #{$global-right}: 0;
                    top: 0;
                    width: $product-main-aside-width--large;
                }
            }
        }

        &.m-discontinued {
            .c-product-main__inner {
                @include breakpoint(large) {
                    display: grid;
                    grid-template-columns: $product-main-inner-columns;
                }
            }

            .c-product-main__info {
                @include breakpoint(large) {
                    padding: $product-main-discontinued-info--padding--large;
                    grid-column: 1;
                    grid-row: 2;
                    width: auto;
                }
            }

            .c-product-main__image {
                @include breakpoint(large) {
                    padding: $product-main-image-padding--large;
                    position: relative; // used for wishlist icon positioning
                    grid-column: 1;
                    grid-row: 1 / 2;
                    width: auto;
                }
            }

            .c-product-main__recommendation {
                @include breakpoint(large) {
                    grid-column: 2;
                    grid-row: 1 / 3;
                    width: auto;
                }
            }
        }
    }

    .c-product-main__discount {
        margin: $product-main-discount-margin; //quantity width

        @include breakpoint(large) {
            margin: $product-main-discount-margin--large;  //quantity width + margin
        }
    }

    .c-product-main__inner {
        @include layout;

        position: relative;

        @include breakpoint(large) {
            @include clearfix;

            display: grid;
            grid-template-columns: $product-main-image-width--large $product-main-aside-width--large;
            grid-template-rows: $product-main-inner-grid-template-rows--large;
        }
    }

    .c-product-main__image {
        padding: $product-main-image-padding;
        position: $product-main-image-position; // used for wishlist icon positioning

        @include breakpoint(large) {
            padding: $product-main-image-padding--large;
            position: $product-main-image-position--large; // used for wishlist icon positioning
            grid-column: 1;
            grid-row: 1 / 4;
            width: 100%;
        }
    }

    .c-product-main__icons {
        position: absolute;
        top: $product-main-icons-top;
        #{$global-right}: $product-main-icons-right;

        @include breakpoint(large) {
            top: $product-main-icons-top--large;
            #{$global-right}: $product-main-icons-right--large;
        }
    }

    .c-product-main__recommendation {
        @include breakpoint(large) {
            grid-column: 2;
            grid-row: 1 / 3;
            width: auto;
            padding: $product-main-aside-padding--large;
        }
    }

    .c-product-main__recommendation-title {
        @include text-style(
            $font: (
                small: $product-main-recommendation-title-font,
                large: $product-main-recommendation-title-font--large
            ),
            $margin: (
                small: $product-main-recommendation-title-margin,
                large: $product-main-recommendation-title-margin--large
            ),
            $text-align: (
                small: $product-main-recommendation-title-text-align,
                large: $product-main-recommendation-title-text-align--large
            ),
            $text-transform: (
                small: $product-main-recommendation-title-text-transform
            ),
            $color: (
                small: $product-main-recommendation-title-color
            )
        );
    }

    .c-product-main__recommendation-message {
        @include text-style(
            $font: (
                small: $product-main-recommendation-message-font
            ),
            $margin: (
                small: $product-main-recommendation-message-margin,
                large: $product-main-recommendation-message-margin--large
            ),
            $text-transform: (
                small: $product-main-recommendation-message-text-transform
            ),
            $color: (
                small: $product-main-recommendation-message-color
            )
        );
    }

    .c-product-main__info {
        padding: $product-main-aside-padding; // need ~30px to wishlist icon
        text-align: $product-main-info-text-align;

        @include breakpoint(large) {
            grid-column: 2;
            grid-row: 1;
            width: auto;
            padding: $product-main-aside-padding--large;
            text-align: $product-main-info-text-align--large;
        }
    }

    .c-product-main__brand {
        font: $product-main-brand-font;
    }

    .c-product-main__name-wrapper {
        margin: $product-main-name-margin;

        @include breakpoint(large) {
            margin: $product-main-name-margin--large;
        }
    }

    .c-product-main__name {
        display: inline;
        vertical-align: middle;

        @include text-style(
            $font: (
                small: $product-main-name-font,
                large: $product-main-name-font--large
            ),
            $text-transform: (
                small: $product-main-name-text-transform
            ),
            $color: (
                small: $product-main-name-color
            )
        );
    }

    .c-product-main__headline {
        display: block;

        @include text-style(
            $font: (
                small: $product-main-headline-font,
                large: $product-main-headline-font--large
            ),
            $margin: (
                small: $product-main-headline-margin,
                large: $product-main-headline-margin--large
            ),
            $text-transform: (
                small: $product-main-headline-text-transform
            ),
            $color: (
                small: $product-main-headline-color
            )
        );
    }

    .c-product-main__subtitle {
        display: $product-main-subtitle-display;

        @include breakpoint(large) {
            display: $product-main-subtitle-display--large;
        }
        @include text-style(
            $font: (
                small: $product-main-subtitle-font,
                large: $product-main-subtitle-font--large
            ),
            $margin: (
                small: $product-main-subtitle-margin,
                large: $product-main-subtitle-margin--large
            ),
            $letter-spacing: (
                small: $product-main-subtitle-letter-spacing,
                large: $product-main-subtitle-letter-spacing--large
            ),
            $text-transform: (
                small: $product-main-subtitle-text-transform
            ),
            $color: (
                small: $product-main-subtitle-color,
                large: $product-main-subtitle-color--large
            )
        );
    }

    .c-product-main__rating {
        margin: $product-main-rating-margin;
        display: $product-main-rating-display;
        justify-content: $product-main-rating-justify-content;
        align-items: $product-main-rating-align-items;

        @include breakpoint(large) {
            margin: $product-main-rating-margin--large;
            display: $product-main-rating-display--large;
            justify-content: $product-main-rating-justify-content--large;
            align-items: $product-main-rating-align-items--large;
        }

        &:empty {
            display: none;
        }

        .c-rating {
            font-size: $product-main-rating-rating-font-size;
            margin: $product-main-rating-rating-margin;

            @include breakpoint(large) {
                font-size: $product-main-rating-rating-font-size--large;
                margin: $product-main-rating-rating-margin--large;
            }
        }

        .c-rating__star:not(:first-child) {
            margin-#{$global-left}: $product-main-rating-rating-icon-margin;
        }
    }

    .c-product-main__review {
        display: inline-flex;
        align-items: center;
        font: $product-main-review-font;
        color: $product-main-review-color;
    }

    .c-product-main__review-count {
        font: $product-main-review-count-font;
        text-decoration: $product-main-review-count-text-decoration;
        color: $product-main-review-count-color;
    }

    .c-product-main__review-total {
        text-decoration: $product-main-review-total-text-decoration;
        color: $product-main-review-total-color;
    }

    .c-product-main__review-link {
        text-decoration: $product-main-review-link-text-decoration;
        color: $product-main-review-link-color;
    }

    .c-product-main__review-highlight-total {
        text-decoration: $product-main-review-highlight-total-text-decoration;
        color: $product-main-review-highlight-total-color;
        font: $product-main-review-highlight-total-font;
    }

    .c-product-main__review-highlight-icon {
        font-size: 0;
        margin: $product-main-review-highlight-icon-margin;

        &::before {
            content: $product-main-review-highlight-icon-before-content;
            display: $product-main-review-highlight-icon-before-display;
            font-size: $product-main-review-highlight-icon-before-font-size;
            vertical-align: middle;
            margin: $product-main-review-highlight-icon-before-margin;
            color: $product-main-review-highlight-icon-before-color;
        }
    }

    .c-product-main__review-highlight-icon-image {
        width: $product-main-review-highlight-icon-image-width;
        margin: $product-main-review-highlight-icon-image-margin;
    }

    .c-product-main__review-info {
        @include lora-helper-icon-svg(
            $icon: $product-main-review-info-icon,
            $color: $product-main-review-info-icon-color,
            $size: $product-main-review-info-icon-size,
            $svg-selector: $product-main-review-info-icon-svg-selector,
            $is-mask: $product-main-review-info-icon-is-mask
        );

        font-size: 0;
        margin: $product-main-review-info-margin;
    }

    .c-product-main__short-description {
        color: $product-main-short-description-color;
        font: $product-main-short-description-font;
        margin: $product-main-short-description-margin;
        text-transform: $product-main-short-description-text-transform;
        letter-spacing: $product-main-short-description-letter-spacing;

        @include breakpoint(medium down) {
            display: $product-main-short-description-display;
        }
    }

    .c-product-main__short-description-readmore {
        font: $product-main-short-description-readmore-font;
        color: $product-main-short-description-readmore-color;
        float: $product-main-short-description-readmore-float;
        text-transform: $product-main-short-description-readmore-text-transform;
        letter-spacing: $product-main-short-description-readmore-letter-spacing;
    }

    .c-product-main__controls {
        @include breakpoint(large) {
            grid-column: 2;
            grid-row: 2;
            width: auto;
            padding: $product-main-aside-padding--large;
        }
    }

    .c-product-main__actions {
        display: flex;
        flex-wrap: wrap;
        margin: $product-main-actions-margin;

        @include breakpoint(large) {
            flex-wrap: nowrap; // to prevent wrap if there are long error msg in product-main-error
        }

        &.m-no-margin {
            margin: 0 !important; /* stylelint-disable-line */

            .c-button {
                margin: $product-main-actions-margin;
            }
        }
    }

    .c-product-main__price {
        @include text-style(
            $font: (
                small: $product-main-price-font,
                large: $product-main-price-font--large
            ),
            $margin: (
                small: $product-main-price-margin,
                large: $product-main-price-margin--large
            )
        );
    }

    .c-product-main__quantity {
        flex-basis: $product-main-quantity-flex-basis;

        &:empty {
            display: none;
        }
    }

    .c-product-main__quantity .c-product__quantity-selector {
        margin: $product-main-quantity-margin;

        @include breakpoint(large) {
            margin: $product-main-quantity-margin--large;
        }
    }

    .c-product-main__add-bag {
        flex-grow: $product-main-add-bag-flex-grow;
    }

    .c-product-main__where-to-buy {
        margin: $product-main-where-to-buy-margin;
        flex: $product-main-where-to-buy-flex;

        @include breakpoint(large) {
            margin: $product-main-where-to-buy-margin--large;
        }

        &:empty {
            display: none;
        }

        .c-product-main__where-to-buy-select {
            display: inline-block;
            min-width: $product-main-where-to-buy-select-min-width;

            @include breakpoint(large) {
                min-width: $product-main-where-to-buy-select-min-width--large;
            }
        }
    }

    .c-product-main__error {
        width: $product-main-error-width;

        @include breakpoint(medium down) {
            background: $product-main-error-background--medium-down;
            bottom: $product-main-error-bottom--medium-down;
            #{$global-left}: 0;
            position: absolute;

            .c-field__error-message {
                padding: $product-main-error-padding--medium-down;
                margin: $product-main-error-margin--medium-down;
            }
        }
    }

    .c-product-main__sticky-limit {
        height: 1px;
        margin-top: -1px;
        visibility: hidden;
        pointer-events: none;
    }

    .c-product-main__variations-dropdown {
        width: $product-main-variations-dropdown-width;
        order: $product-main-variations-dropdown-order;

        @include breakpoint(large) {
            order: $product-main-variations-dropdown-order--large;
            width: $product-main-variations-dropdown-width--large;
        }

        &.m-size {
            margin: $product-main-variations-dropdown-margin;
            display: none;
        }

        &.m-producttile {
            width: $product-main-variations-dropdown-producttile-width;
        }

        &.m-fullwidth {
            width: 100%;
        }

        &.m-top {
            margin: $product-main-variations-dropdown-top-margin;
        }
    }

    .c-product-main__swatches-grouped {
        width: 100%;
        margin: $product-main-swatches-grouped-margin;

        @include breakpoint(large) {
            margin: $product-main-swatches-grouped-margin--large;
        }
    }

    button.c-product-main__more-shades { // stylelint-disable-line selector-no-qualifying-type
        position: absolute;
        #{$global-right}: $product-main__more-shade-right;
        top: $product-main__more-shade-top;
        width: $product-main__more-shade-width;

        @include breakpoint(large) {
            width: $product-main__more-shade-width--large;
        }
    }

    .c-product-main__preview-image {
        margin: $product-main-preview-image-margin;
        order: $product-main-preview-image-order;
        width: $product-main-preview-image-width;

        @include breakpoint(large) {
            margin: $product-main-preview-image-margin--large;
            order: $product-main-preview-image-order--large;
            width: $product-main-preview-image-width--large;
        }
    }

    .c-product-main__shipping {
        @include text-style(
            $font: (
                small: $product-main-shipping-font,
                large: $product-main-shipping-font--large
            ),
            $margin: (
                small: $product-main-shipping-margin,
                large: $product-main-shipping-margin--large
            ),
            $padding: (
                small: $product-main-shipping-padding,
                large: $product-main-shipping-padding--large
            ),
            $letter-spacing: (
                small: $product-main-shipping-letter-spacing
            ),
            $text-transform: (
                small: $product-main-shipping-text-transform
            )
        );
    }

    .c-product-main__services {
        padding: $product-main-services-padding;
        margin: $product-main-services-margin;
        border-top: $product-main-services-border-top;
        border-bottom: $product-main-services-border-bottom;

        @include breakpoint(large) {
            padding: $product-main-services-padding--large;
            margin: $product-main-services-margin--large;
            border-top: $product-main-services-border-top--large;
            border-bottom: $product-main-services-border-bottom--large;
        }
    }
}
