$product-view360-button-icon: 'view360' !default;
$product-view360-button-icon-usesvg: true !default;
$product-view360-button-icon-size: 100% !default;
$product-view360-button-color: color(primary) !default;
$product-view360-button-color-hover: color(primary-active) !default;
$product-view360-button-size: rem-calc(40) !default;
$product-view360-button-opacity: 1 !default;
$product-view360-button-background: color(light) !default;
$product-view360-button-box-shadow: rem-calc(0 0 4 0) rgba(color(dark), 0.2) !default;
$product-view360-button-border-radius: 50% !default;

@mixin lora-components-product-view360-button {
    .c-product-view360-button {
        cursor: pointer;
        display: inline-block;
        opacity: $product-view360-button-opacity;
        background: $product-view360-button-background;
        box-shadow: $product-view360-button-box-shadow;
        border-radius: $product-view360-button-border-radius;

        &::after {
            @include svg-icon($product-view360-button-icon, $product-view360-button-color, $size: $product-view360-button-icon-size, $usesvg: $product-view360-button-icon-usesvg);

            content: '';
            display: block;
            height: $product-view360-button-size;
            width: $product-view360-button-size;
        }

        &:hover::after {
            @include svg-icon($product-view360-button-icon, $product-view360-button-color-hover, $color-change: true, $usesvg: $product-view360-button-icon-usesvg);
        }
    }
}
