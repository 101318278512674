$phonetic-audio-margin: 0 !default;
$phonetic-audio-margin--large: rem-calc(0 0 0 10) !default;
$phonetic-audio-padding: rem-calc(5) !default;
$phonetic-audio-icon: audio !default;
$phonetic-audio-icon-color: color(organism-background) !default;
$phonetic-audio-icon-color-hover: color(info) !default;
$phonetic-audio-icon-width: rem-calc(25) !default;
$phonetic-audio-icon-height: rem-calc(25) !default;
$phonetic-audio-icon-usesvg: true !default;

@mixin lora-components-phonetic-audio {
    .c-phonetic-audio {
        vertical-align: middle;
        display: inline-block;
        margin: $phonetic-audio-margin;
        padding: $phonetic-audio-padding;

        .c-phonetic-audio-icon {
            @include svg-icon($phonetic-audio-icon, $phonetic-audio-icon-color, 100%, left, no-repeat, $usesvg: $phonetic-audio-icon-usesvg);

            cursor: pointer;
            width: $phonetic-audio-icon-width;
            height: $phonetic-audio-icon-height;

            &:hover,
            &.m-active {
                @include svg-icon($phonetic-audio-icon, $phonetic-audio-icon-color-hover, 100%, left, no-repeat, $color-change: true, $usesvg: $phonetic-audio-icon-usesvg);
            }
        }

        @include breakpoint(large) {
            margin: $phonetic-audio-margin--large;
        }
    }
}
