$product-sticky-bar-height: rem-calc(40) !default;
$product-sticky-bar-border-bottom: 1px solid color(border) !default;
$product-sticky-bar-top: 0 !default;
$product-sticky-bar-padding: rem-calc(5 0) !default;
$product-sticky-bar-transition: top 0.3s ease-out !default;
$product-sticky-bar-background: color(light) !default;
$product-sticky-bar-box-shadow: null !default;
$product-sticky-bar-title-color: null !default;
$product-sticky-bar-title-font-size: rem-calc(14) !default;
$product-sticky-bar-title-font-weight: normal !default;
$product-sticky-bar-title-margin: 0 !default;
$product-sticky-bar-title-font-family: $font-primary !default;
$product-sticky-bar-title-font: $product-sticky-bar-title-font-weight #{$product-sticky-bar-title-font-size} / 1.4 $product-sticky-bar-title-font-family !default;
$product-sticky-bar-title-text-transform: none !default;
$product-sticky-bar-description-font-family: null !default;
$product-sticky-bar-description-font-weight: null !default;
$product-sticky-bar-description-font-style: null !default;
$product-sticky-bar-description-font-size: rem-calc(12) !default;
$product-sticky-bar-description-margin: 0 !default;
$product-sticky-bar-description-color: null !default;
$product-sticky-bar-description-text-transform: none !default;
$product-sticky-bar-description-letter-spacing: null !default;
$product-sticky-bar-inner-gap: rem-calc(20) !default;
$product-sticky-bar-item-margin: 0 !default;
$product-sticky-bar-quantity-margin: rem-calc(0 25 0 0) !default;
$product-sticky-bar-price-display: block !default;
$product-sticky-bar-price-margin: $product-sticky-bar-item-margin !default;
$product-sticky-bar-where-to-buy-margin: 0 !default;
$product-sticky-bar-where-to-buy-text-align: #{$global-right} !default;
$product-sticky-bar-item-image-border: 1px solid color(border) !default;
$product-sticky-bar-item-image-width: rem-calc(70) !default;
$product-sticky-bar-item-image-height: rem-calc(70) !default;
$product-sticky-bar-item-description-width: null !default;
$product-sticky-bar-item-description-max-width: null !default;
$product-sticky-bar-item-spacer-flex: 1 0 auto !default;
$product-sticky-bar-item-variation-display: flex !default;
$product-sticky-bar-item-variation-flex: 1 0 auto !default;
$product-sticky-bar-item-variation-margin: $product-sticky-bar-item-margin !default;
$product-sticky-bar-item-variation-min-width: rem-calc(150) !default;
$product-sticky-bar-item-variation-max-width: rem-calc(300) !default;
$product-sticky-bar-item-variation-section-flex: 1 !default;
$product-sticky-bar-item-variation-section-padding: rem-calc(0 5) !default;
$product-sticky-bar-item-variation-section-margin: 0 !default;
$product-sticky-bar-item-variation-dropdown-width: 100% !default;
$product-sticky-bar-item-actions-margin: 0 !default;
$product-sticky-bar-item-wishlist-display: flex !default;
$product-sticky-bar-wishlist-link-font-size: rem-calc(14) !default;
$product-sticky-bar-wishlist-link-font-weight: null !default;
$product-sticky-bar-wishlist-link-margin: rem-calc(0 0 0 10) !default;
$product-sticky-bar-wishlist-text-transform: none !default;
$product-sticky-bar-add-bag-error-margin: rem-calc(2) 0 0 !default;
$product-sticky-bar-add-bag-flex-grow: 0 !default;
$product-sticky-bar-add-bag-error-white-space: nowrap !default;
$product-sticky-bar-add-bag-width: rem-calc(260) !default;
$product-sticky-bar-actions-margin--medium-down: 0 !default;
$product-sticky-bar-actions-wrap--medium-down: nowrap !default;
$product-sticky-bar-letter-spacing: null !default;
$product-sticky-bar-availability-align-self: center !default;
$product-sticky-bar-availability-label-color: color(primary) !default;
$product-sticky-bar-availability-label-text-transform: uppercase !default;
$product-sticky-bar-rating-margin: rem-calc(0 10 0 0) !default;

@mixin lora-components-product-sticky-bar {
    // Narrow screen always visible bar
    @include breakpoint(medium down) {
        .l-footer {
            margin-bottom: $product-sticky-bar-height;
        }
    }

    // Hide regular sticky bar
    .c-sticky-bar.m-footer,
    .c-toaster {
        // global sticky bar
        display: none;
    }

    // Mobile PDP sticky bar should be available only is JS component is not suppressed
    [data-component="product/ProductStickyBar"] {
        .c-product-sticky-bar__item.m-actions:not(.m-static-mobile) {
            @include breakpoint(medium down) {
                flex-wrap: $product-sticky-bar-actions-wrap--medium-down;
                bottom: 0;
                #{$global-left}: 0;
                position: fixed;
                #{$global-right}: 0;
                z-index: z(stickyBar);
                margin: $product-sticky-bar-actions-margin--medium-down;
            }
        }
    }

    .c-product-sticky-bar__item.m-sticky-visible {
        display: none;

        .c-rating {
            margin: $product-sticky-bar-rating-margin;
            vertical-align: initial;
        }
    }

    .c-product-sticky-bar.m-sticked {
        @include breakpoint(large) {
            background: $product-sticky-bar-background;
            border-bottom: $product-sticky-bar-border-bottom;
            box-shadow: $product-sticky-bar-box-shadow;
            padding: $product-sticky-bar-padding;
            position: fixed;
            #{$global-left}: 0;
            #{$global-right}: 0;
            top: $product-sticky-bar-top;
            transition: $product-sticky-bar-transition;
            z-index: z(headerStickyBar);
        }

        .m-sticky-visible {
            display: block;
        }

        .m-sticky-hidden {
            display: none;
        }

        .c-product-main__add-bag {
            flex-grow: $product-sticky-bar-add-bag-flex-grow;
        }

        .c-product-add-bag {
            @include text-truncate;

            min-width: max-content;
        }

        .c-product-main__quantity .c-product__quantity-selector {
            margin: $product-sticky-bar-quantity-margin;
        }

        .c-product-main__where-to-buy {
            margin: $product-sticky-bar-where-to-buy-margin;
            text-align: $product-sticky-bar-where-to-buy-text-align;

            &:empty {
                margin: 0;
            }
        }

        .c-product-sticky-bar__inner {
            @include layout;

            align-items: center;
            display: flex;
            gap: $product-sticky-bar-inner-gap;
        }

        .c-product-sticky-bar__item {
            margin: $product-sticky-bar-item-margin;

            &:last-child {
                margin-#{$global-right}: 0;
            }

            &.m-image {
                border: $product-sticky-bar-item-image-border;
                flex: 0 0 auto;
                height: $product-sticky-bar-item-image-height;
                width: $product-sticky-bar-item-image-width;
            }

            &.m-description {
                flex: 1 1 auto;
                max-width: $product-sticky-bar-item-description-max-width;
                overflow: hidden;
                width: $product-sticky-bar-item-description-width;
            }

            &.m-spacer {
                flex: $product-sticky-bar-item-spacer-flex;
            }

            &.m-variations {
                flex: $product-sticky-bar-item-variation-flex;
                display: $product-sticky-bar-item-variation-display;
                margin: $product-sticky-bar-item-variation-margin;
                min-width: $product-sticky-bar-item-variation-min-width;
                max-width: $product-sticky-bar-item-variation-max-width;

                .c-variation-selection__title,
                .c-swatches,
                .c-variations-carousel,
                .c-variations-carousel__label,
                .c-product-tile__variations-label,
                .c-swatches-grouped,
                .c-swatches-grouped__toggle,
                .c-swatches-grouped__toggle-content,
                .c-swatches__toggle,
                .c-swatches__toggle-content,
                .c-swatches__swatch-selected,
                .c-swatches__view-all,
                .c-preview-image {
                    display: none;
                }

                .c-variation-section__content {
                    width: 100%;
                    flex: $product-sticky-bar-item-variation-section-flex;
                    padding: $product-sticky-bar-item-variation-section-padding;
                    margin: $product-sticky-bar-item-variation-section-margin;
                }

                .c-variations-dropdown {
                    display: inline-block;
                    margin: 0;
                    width: $product-sticky-bar-item-variation-dropdown-width;
                }
            }

            &.m-wishlist {
                align-items: center;
                display: $product-sticky-bar-item-wishlist-display;
                white-space: nowrap;
            }

            &.m-actions {
                margin: $product-sticky-bar-item-actions-margin;

                @include breakpoint(large) {
                    width: auto;
                }
            }
        }

        .c-product-sticky-bar__availability {
            align-self: $product-sticky-bar-availability-align-self;

            .c-product-availability__label {
                color: $product-sticky-bar-availability-label-color;
                text-transform: $product-sticky-bar-availability-label-text-transform;
            }
        }

        .c-product-main__price {
            display: $product-sticky-bar-price-display;
            margin: $product-sticky-bar-price-margin;
        }
    }

    .c-product-sticky-bar__title {
        @include text-truncate;

        font: $product-sticky-bar-title-font;
        text-transform: $product-sticky-bar-title-text-transform;
        margin: $product-sticky-bar-title-margin;
        letter-spacing: $product-sticky-bar-letter-spacing;
        color: $product-sticky-bar-title-color;
    }

    .c-product-sticky-bar__description {
        @include text-truncate;

        color: $product-sticky-bar-description-color;
        font-family: $product-sticky-bar-description-font-family;
        font-weight: $product-sticky-bar-description-font-weight;
        font-style: $product-sticky-bar-description-font-style;
        font-size: $product-sticky-bar-description-font-size;
        margin: $product-sticky-bar-description-margin;
        text-transform: $product-sticky-bar-description-text-transform;
        letter-spacing: $product-sticky-bar-description-letter-spacing;
    }

    .c-product-sticky-bar__wishlist-link {
        font-size: $product-sticky-bar-wishlist-link-font-size;
        font-weight: $product-sticky-bar-wishlist-link-font-weight;
        margin: $product-sticky-bar-wishlist-link-margin;
        text-transform: $product-sticky-bar-wishlist-text-transform;
    }
}
