$media-selector-margin: rem-calc(0 -5) !default;
$media-selector-margin--large: null !default;
// Item
$media-selector-item-width: rem-calc(90) !default;
$media-selector-item-width--large: null !default;
$media-selector-item-margin: rem-calc(0 5 5) !default;
$media-selector-item-margin--large: null !default;
// Add More button
$media-selector-add-more-border: 1px dashed color(disabled) !default;
$media-selector-add-more-background: color(light) !default;
// Add product button
$media-selector-add-product-text-align: center !default;
$media-selector-add-product-border: 1px dashed color(text-secondary) !default;
$media-selector-add-product-border-radius: rem-calc(4) !default;
$media-selector-add-product-background: color(global-background) !default;
$media-selector-add-product-padding: rem-calc(29 16) !default;
$media-selector-add-product-font: 400 #{rem-calc(12)} / 1 $font-primary !default;
$media-selector-add-product-color: color(text-secondary) !default;
$media-selector-add-product-transition: background-color 0.25s, color 0.25s, border-color 0.25s ease-out !default;
$media-selector-add-product-hover-background: color(primary-background) !default;
$media-selector-add-product-hover-border-color: color(primary) !default;
$media-selector-add-product-hover-color: color(primary) !default;
// Add product button icon
$media-selector-add-product-icon-size: rem-calc(12) !default;
$media-selector-add-product-icon-size--large: rem-calc(12) !default;
$media-selector-add-product-icon: plus !default;
$media-selector-add-product-icon-color: color(text-secondary) !default;
$media-selector-add-product-icon-hover-color: color(primary) !default;
$media-selector-add-product-icon-margin: 0 auto rem-calc(16) !default;
$media-selector-add-product-icon-transition: background-color 0.25s ease-out !default;
$media-selector-add-product-icon-usesvg: true !default;

@mixin lora-components-media-selector {
    .c-media-selector {
        display: flex;
        flex-wrap: wrap;
        margin: $media-selector-margin;

        @include breakpoint(large) {
            margin: $media-selector-margin--large;
        }
    }

    .c-media-selector__item {
        width: $media-selector-item-width;
        margin: $media-selector-item-margin;

        @include breakpoint(large) {
            width: $media-selector-item-width--large;
            margin: $media-selector-item-margin--large;
        }
    }

    .c-media-selector__add-more {
        display: block;
        position: relative;
        padding-bottom: 100%;
        border: $media-selector-add-more-border;
        background: $media-selector-add-more-background;
    }

    .c-media-selector__add-more-icon {
        @include absolute-center;
    }

    .c-media-selector__add-product {
        cursor: pointer;
        display: block;
        width: 100%;
        text-align: $media-selector-add-product-text-align;
        border: $media-selector-add-product-border;
        border-radius: $media-selector-add-product-border-radius;
        background: $media-selector-add-product-background;
        padding: $media-selector-add-product-padding;
        font: $media-selector-add-product-font;
        color: $media-selector-add-product-color;
        transition: $media-selector-add-product-transition;

        &::before {
            @include svg-icon($media-selector-add-product-icon, $media-selector-add-product-icon-color, 100%, left, no-repeat, $usesvg: $media-selector-add-product-icon-usesvg);

            content: '';
            display: block;
            height: $media-selector-add-product-icon-size;
            margin: $media-selector-add-product-icon-margin;
            width: $media-selector-add-product-icon-size;
            transition: $media-selector-add-product-icon-transition;

            @include breakpoint(large) {
                height: $media-selector-add-product-icon-size--large;
                width: $media-selector-add-product-icon-size--large;
            }
        }

        &:hover {
            background: $media-selector-add-product-hover-background;
            border-color: $media-selector-add-product-hover-border-color;
            color: $media-selector-add-product-hover-color;

            &::before {
                @include svg-icon($media-selector-add-product-icon, $media-selector-add-product-icon-hover-color, 100%, left, no-repeat, $color-change: true, $usesvg: $media-selector-add-product-icon-usesvg);
            }
        }
    }
}
