$product-routine-columns--large: 4 !default;
$product-routine-item-padding--large: rem-calc(0 15) !default;
$product-routine-item-margin--medium-dowm: rem-calc(0 0 20) !default;
$product-routine-section-description-display--medium-down: none !default;
// Offset from top for product routine checkbox on mobile
$product-routine-item-check-field-margin-top: rem-calc((75-20)/2) !default;
// Product tile
$product-routine-grid-tile-flex--medium-dowm: 1 1 auto !default;
$product-routine-tile-padding--large: rem-calc(0 0 40) !default;
$product-routine-tile-figure-flex--medium-dowm: 0 0 auto !default;
$product-routine-tile-figure-width--medium-dowm: rem-calc(75) !default;
$product-routine-tile-figure-margin--medium-dowm: rem-calc(0 15 0 0) !default;
$product-routine-tile-thumbnail-margin--medium-dowm: 0 !default;
$product-routine-tile-caption-margin-top--medium-dowm: rem-calc(5) !default;
$product-routine-tile-caption-text-align--medium-dowm: $global-left !default;
$product-routine-tile-brand-margin--medium-dowm: 0 !default;
$product-routine-tile-name-margin--medium-dowm: 0 !default;
$product-routine-tile-info-margin--medium-dowm: 0 !default;
$product-routine-tile-info-item-text-align--medium-dowm: $global-left !default;
$product-routine-tile-info-item-align-self--medium-dowm: start !default;
// Summary section
$product-routine-summary-background--large: color(global-background) !default;
$product-routine-summary-padding--large: rem-calc(50 20 30) !default;
$product-routine-summary-border--large: none !default;
$product-routine-summary-min-width--large: rem-calc(280) !default;
// Routine Description
$product-routine-description-font: normal #{rem-calc(24)} / 1.25 $font-primary !default;
$product-routine-description-color: color(text) !default;
$product-routine-description-text-transform: uppercase !default;
$product-routine-description-margin: rem-calc(0 10 40) !default;
$product-routine-description-text-align: center !default;
// Routine summary product name
$product-routine-product-name-font: normal #{rem-calc(14)} / 1.25 $font-primary !default;
$product-routine-product-name-color: color(text) !default;
$product-routine-product-name-margin: rem-calc(5 0) !default;
$product-routine-product-name-text-align: center !default;
$product-routine-product-name-text-transform: none !default;
// Actions
$product-routine-actions-margin--large: rem-calc(40 0 0) !default;

@mixin lora-components-product-routine {
    .c-routine {
        position: relative;

        @include breakpoint(large) {
            display: flex;
            justify-content: center;
        }
    }

    .c-routine-section {
        .c-section__description {
            @include breakpoint(medium down) {
                display: $product-routine-section-description-display--medium-down;
            }
        }
    }

    .c-routine__item {
        @include breakpoint(medium down) {
            align-items: flex-start;
            display: flex;
            margin: $product-routine-item-margin--medium-dowm;
        }
        @include breakpoint(large) {
            padding: $product-routine-item-padding--large;
            width: percentage(1 / $product-routine-columns--large);
        }

        .c-check-field {
            flex: 0 0 auto;
            margin-top: $product-routine-item-check-field-margin-top;

            @include breakpoint(large) {
                display: none;
            }
        }

        .c-check-field__text {
            @include element-invisible;
        }

        .c-product-tile__wishlist {
            display: none;
        }

        .c-product-tile__variations-group {
            @include breakpoint(medium down) {
                height: auto;
            }
        }

        .c-product-tile__swatch-group,
        .c-product-tile__description,
        .c-product-badge,
        .c-product-tile__actions {
            @include breakpoint(medium down) {
                display: none;
            }
        }

        .c-product-grid__tile {
            @include breakpoint(medium down) {
                flex: $product-routine-grid-tile-flex--medium-dowm;
            }
            @include breakpoint(large) {
                height: 100%;
            }
        }

        .c-product-tile {
            @include breakpoint(medium down) {
                display: flex;
                flex-flow: row;
            }
            @include breakpoint(large) {
                padding: $product-routine-tile-padding--large;
            }
        }

        .c-product-tile__figure {
            @include breakpoint(medium down) {
                flex: $product-routine-tile-figure-flex--medium-dowm;
                margin: $product-routine-tile-figure-margin--medium-dowm;
                width: $product-routine-tile-figure-width--medium-dowm;
            }
        }

        .c-product-tile__thumbnail {
            @include breakpoint(medium down) {
                margin: $product-routine-tile-thumbnail-margin--medium-dowm;
            }
        }

        .c-product-tile__caption {
            @include breakpoint(medium down) {
                margin-top: $product-routine-tile-caption-margin-top--medium-dowm;
                text-align: $product-routine-tile-caption-text-align--medium-dowm;
            }
        }

        .c-product-tile__info {
            @include breakpoint(medium down) {
                margin-top: $product-routine-tile-info-margin--medium-dowm;
            }

            .c-product-tile__info-item {
                @include breakpoint(medium down) {
                    text-align: $product-routine-tile-info-item-text-align--medium-dowm;
                    align-self: $product-routine-tile-info-item-align-self--medium-dowm;
                }
            }
        }

        .c-product-tile__brand {
            @include breakpoint(medium down) {
                height: auto;
                overflow: visible;
                margin: $product-routine-tile-brand-margin--medium-dowm;
            }
        }

        .c-product-tile__name {
            @include breakpoint(medium down) {
                height: auto;
                overflow: hidden;
                margin: $product-routine-tile-name-margin--medium-dowm;
            }
        }
    }

    .c-routine__summary {
        @include breakpoint(large) {
            background: $product-routine-summary-background--large;
            border: $product-routine-summary-border--large;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            padding: $product-routine-summary-padding--large;
            position: relative;
            width: percentage(1 / $product-routine-columns--large);
            min-width: $product-routine-summary-min-width--large;
        }
    }

    .c-routine__description {
        color: $product-routine-description-color;
        font: $product-routine-description-font;
        margin: $product-routine-description-margin;
        text-align: $product-routine-description-text-align;
        text-transform: $product-routine-description-text-transform;

        @include breakpoint(medium down) {
            display: none;
        }
    }

    .c-routine__products-list {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
        justify-content: space-evenly;

        @include breakpoint(medium down) {
            display: none;
        }
    }

    .c-routine__product-name {
        color: $product-routine-product-name-color;
        font: $product-routine-product-name-font;
        margin: $product-routine-product-name-margin;
        text-align: $product-routine-product-name-text-align;
        text-transform: $product-routine-product-name-text-transform;
    }

    .c-routine__actions {
        @include breakpoint(large) {
            margin: $product-routine-actions-margin--large;
        }
    }
}
